import { Injectable } from "@angular/core";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { Selector, State, StateContext } from "@ngxs/store";

interface Model {
  isExporting: boolean
}

@State({
  name: 'export',
  defaults: {
    isExporting: false
  }
})
@Injectable()
export class ExportState {
  @Receiver()
  public static setIsExporting(
    { patchState }: StateContext<Model>,
    { payload }: EmitterAction<boolean>
  ): void {
    patchState({ isExporting: payload });
  }

  @Selector()
  public static getIsExporting(
    state: Model
  ): boolean {
    return state.isExporting;
  }
}
