import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { TranslocoService } from '@ngneat/transloco';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { isEqual } from 'lodash-es';
import { Category } from 'src/entities/category.entity';
import { CentralCustomization } from 'src/entities/central-customization.entity';
import { CentralMenu } from 'src/entities/central-menu.entity';
import { Carousel, Central } from 'src/entities/central.entity';
import { Language } from 'src/entities/languages.entity';
import { Api } from './api/api.decorator';
import { APIService } from './api/api.service';
import { CentralState, CentralStateModel } from './store/central';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class AppService  {
  @Emitter(CentralState.setCentral)
  private setCentral$!: Emittable<Partial<CentralStateModel>>;

  @SelectSnapshot(CentralState.getCentralState)
  central!: CentralStateModel

  @Api(CentralMenu)
  public menuAPI!: APIService<CentralMenu>

  @Api(CentralCustomization)
  public customizationApi!: APIService<CentralCustomization>

  @Api(Language)
  public langApi!: APIService<Language>

  @Api(Carousel)
  public carouselApi!: APIService<Carousel>

  @Api(Central)
  public centralApi!: APIService<Central>

  @Api(Category)
  categoryApi!: APIService<Category>

  public statePayload: Partial<CentralStateModel> = {}

  constructor(private readonly title: Title, private readonly meta: Meta, private translocoService: TranslocoService) {}

  async resolve(): Promise<void> {
    const carousels = await this.carouselApi.get()
    const central = await this.centralApi.get<Central>()
    const menus = await this.menuAPI.get()
    const customization = await this.customizationApi.get<CentralCustomization>()
    const categories = await this.categoryApi.get()

    this.statePayload.carousels = carousels.length ? carousels : [
      {
        id: 1,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        image: 'https://picsum.photos/seed/default-central/1920/600.webp?blur&grayscale',
        imageSrc: 'https://picsum.photos/seed/default-central/1920/600.webp?blur&grayscale',
        actions: [],
        active: true,
        createdAt: new Date(),
        updatedAt: new Date(),
      }
    ]

    if (!isEqual(this.central.companyName, central.companyName)) {
      this.statePayload.companyName = central.companyName
    }

    if (!isEqual(this.central.periods, central.periods)) {
      this.statePayload.periods = central.periods
    }

    if (!isEqual(this.central.languages, central.languages)) {
      this.statePayload.languages = central.languages

      if (central.languages?.length === 1) {
        this.translocoService.setActiveLang(central.languages[0].initials)
      }
    }

    if(!isEqual(this.central.customization, customization)){
      this.statePayload.customization = customization
    }

    if(!isEqual(this.central.categories, categories)){
      this.statePayload.categories = categories
    }

    if (!isEqual(this.central.menus, menus)) {
      this.statePayload.menus = menus
    }

    this.title.setTitle(`${customization.title}`);
    this.meta.addTag({
      name: 'description',
      content: customization.description
    })

    Sentry.setTag("company", central.companyName);

    this.setCentral$.emit(this.statePayload)
  }
}
