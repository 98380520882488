import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { ImaginePortalModule } from '../@imagine/i-portal/i-portal.module';
import { StoreModule } from './store';
import { ApiModule } from './api';
import { TranslocoRootModule } from './transloco-root.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CentralHomeComponent } from './pages/central/pages/home/central.component';
import { CarouselModule } from './components/carousel/carousel.module';
import { MenuCardModule } from './components/menu/menu-card/menu-card.module';
import { RedirectModule } from './shared/services/redirect/redirect.module';
import { CentralSearchService } from './pages/central/pages/search/search.service';
import { CookieConsentComponent } from './components/cookieconsent/cookieconsent.component';
import { provideEchartsCore } from 'ngx-echarts';

import * as echarts from 'echarts/core';
import naturaTheme from './../assets/themes/natura.json'
import defaultTheme from './../assets/themes/default.json'

//@ts-ignore
import ptLocale from 'echarts/lib/i18n/langPT-br'
//@ts-ignore
import esLocale from 'echarts/lib/i18n/langES'

import { BarChart, LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  TimelineComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  TimelineComponent,
  ToolboxComponent,
  DataZoomComponent,
]);

echarts.registerTheme('default', defaultTheme)
echarts.registerTheme('natura', naturaTheme)

echarts.registerLocale('PT-br', ptLocale)
echarts.registerLocale('ES', esLocale)

@NgModule({
  declarations: [AppComponent, CentralHomeComponent],
  imports: [
    AppRoutingModule,
    TranslocoRootModule,
    BrowserAnimationsModule,

    ApiModule,

    CarouselModule,
    MenuCardModule,
    RedirectModule,

    HeaderModule,
    FooterModule,

    ImaginePortalModule,
    StoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CookieConsentComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    AppService,
    CentralSearchService,
    provideEchartsCore({ echarts}),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ]
})
export class AppModule {
  constructor(trace: TraceService) {}
}
