import { Endpoint } from "src/app/api/api.decorator";
import { IndicatorBasic } from "./indicator.entity";

@Endpoint('categories')
export class Category {
  id!: number;

  name!: string;

  type!: 'ods' | 'framework' | 'general';

  code!: string;

  description?: string | null;

  slug!: string;

  color!: string | null;

  isClimate!: boolean;

  children!: Category[] | null;

  permalink!: string | null;

  parent!: Category | null;

  // goals: CentralCategoryGoalModel[];
}

@Endpoint('category-map')
export class CategoryMap {}

export type CategoryMapData = Map<number, IndicatorBasic[]>

export class CategoryGoal {
  name!: string;

  description!: string | null;
}
