import { Injectable } from "@angular/core";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { Selector, State, StateContext } from "@ngxs/store";
import { Category } from "src/entities/category.entity";
import { CentralCustomization } from "src/entities/central-customization.entity";
import { Carousel, CentralPeriod } from 'src/entities/central.entity';
import { Indicator } from 'src/entities/indicator.entity';
import { Language } from 'src/entities/languages.entity';
import { CentralMenu } from '../../../entities/central-menu.entity';

export interface CentralStateModel {
  selectedCategory: Category | undefined;
  indicators: Indicator[];
  selectedIndicator: Indicator | undefined;
  companyName: string;

  menus: CentralMenu[];
  categories: Category[];
  periods: CentralPeriod[];
  languages: Language[];
  carousels: Carousel[];
  customization: CentralCustomization | undefined
}

@State<CentralStateModel>({
  name: 'central',
  defaults: {
    selectedCategory: undefined,
    indicators: [],
    selectedIndicator: undefined,

    carousels: [],
    categories: [],
    menus: [],
    companyName: '',
    periods: [],
    languages: [
      {
        id: 1,
        name: 'Português (BR)',
        initials: 'br',
      },
    ],
    customization: undefined
  },
})
@Injectable()
export class CentralState {
  constructor() {}

  @Receiver()
  public static setSelectedIndicator(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Indicator>
  ): void {
    patchState({ selectedIndicator: payload });
  }

  @Receiver()
  public static setSelectedCategory(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Category>
  ): void {
    patchState({ selectedCategory: payload });
  }

  @Receiver()
  public static setIndicators(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Indicator[]>
  ): void {
    patchState({ indicators: payload });
  }

  @Receiver()
  public static setCarousels(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Carousel[]>
  ): void {
    patchState({ carousels: payload });
  }

  @Receiver()
  public static setMenus(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<CentralMenu[]>
  ): void {
    patchState({ menus: payload });
  }

  @Receiver()
  public static setCompanyName(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<string>
  ): void {
    patchState({ companyName: payload });
  }

  @Receiver()
  public static setPeriods(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<CentralPeriod[]>
  ): void {
    patchState({ periods: payload });
  }

  @Receiver()
  public static setLanguages(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Language[]>
  ): void {
    patchState({ languages: payload });
  }

  @Receiver()
  public static setCustomization(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<CentralCustomization>
  ): void {
    patchState({ customization: payload });
  }

  @Receiver()
  public static setCentral(
    { patchState }: StateContext<CentralState>,
    { payload }: EmitterAction<Partial<CentralStateModel>>
  ): void {
    patchState({
      ...payload
    })
  }

  @Selector()
  public static getSelectedIndicator(
    state: CentralStateModel
  ): Indicator | undefined {
    return state.selectedIndicator;
  }

  @Selector()
  public static getSelectedCategory(state: CentralStateModel): Category | undefined {
    return state.selectedCategory;
  }

  @Selector()
  public static getIndicators(state: CentralStateModel): Indicator[] {
    return state.indicators;
  }

  @Selector()
  public static getCarousels(state: CentralStateModel): Carousel[] {
    return state.carousels;
  }

  @Selector()
  public static getMenus(state: CentralStateModel): CentralMenu[] {
    return state.menus;
  }

  @Selector()
  public static getCompanyName(state: CentralStateModel): string {
    return state.companyName;
  }

  @Selector()
  public static getPeriods(state: CentralStateModel): CentralPeriod[] {
    return state.periods;
  }

  @Selector()
  public static getLanguages(state: CentralStateModel): Language[] {
    return state.languages;
  }

  @Selector()
  public static getCustomization(state: CentralStateModel): CentralCustomization | undefined {
    return state.customization;
  }

  @Selector()
  public static getCategories(state: CentralStateModel): Category[] {
    return state.categories;
  }

  @Selector()
  public static getCentralState(state: CentralStateModel): CentralState {
    return state
  }
}
