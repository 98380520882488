import { Component, ElementRef, ViewChild} from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState, CentralStateModel } from '../../store/central';
import { Language } from 'src/entities/languages.entity';
import { CentralCustomization } from 'src/entities/central-customization.entity';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @ViewChild('headerContainer') headerContainer!: ElementRef
  @ViewChild('langButton') langButtonRef!: CdkOverlayOrigin

  public isMenuOpened: boolean = false;
  public isLangSwitchOpened: boolean = false;

  @ViewSelectSnapshot(CentralState.getLanguages)
  public languages!: Language[];

  @ViewSelectSnapshot(CentralState.getCentralState)
  public central!: CentralStateModel;

  @ViewSelectSnapshot(CentralState.getCustomization)
  public customization!: CentralCustomization | null

  constructor() {}
}
