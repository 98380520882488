import Hotjar from "@hotjar/browser"
import { AnalyticsInstance } from "analytics";

type AnalyticsPlugin<T> = {
  /** Name of plugin */
  name: string;

  /** exposed events of plugin */
  EVENTS?: any;

  /** Configuration of plugin */
  config?: T;

  /** Load analytics scripts method */
  initialize?: (params: AnalyticsPluginParams<T>) => void;

  /** Page visit tracking method */
  page?: (params: AnalyticsPluginParams<T>) => void;

  /** Custom event tracking method */
  track?: (params: AnalyticsPluginParams<T>) => void;

  /** User identify method */
  identify?: (params: AnalyticsPluginParams<T>) => void;

  /** Function to determine if analytics script loaded */
  loaded?: (params: AnalyticsPluginParams<T>) => void;

  /** Fire function when plugin ready */
  ready?: (params: AnalyticsPluginParams<T>) => void;
};

type AnalyticsPluginParams<T> = {
  abort: () => void;
  instance: AnalyticsInstance;
  plugins: { name: string, config: T, enabled: boolean, initialized: boolean, loaded: boolean }[]
  payload: {
    anonymousId: string
    type: string
    properties: {
      title: string,
      url: string,
      path: string,
      hash: string,
      search: string,
      width: number,
      height: number,
      referrer: string
    }
    userId: string
  },
  config: T
}

type AnalyticsPluginConfig = {
  siteId: number,
  version: number
}

function hotjarAnalytics(userConfig: AnalyticsPluginConfig): AnalyticsPlugin<AnalyticsPluginConfig> {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'hotjar',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      siteId: userConfig.siteId,
      version: userConfig.version
    },
    initialize: ({ config }) => {
      Hotjar.init(config.siteId, config.version)
    },
    page: ({ payload }) => {
      Hotjar.stateChange(payload.properties.url)
    },
    track: ({ payload }) => {
      // call provider specific event tracking
    },
    identify: ({ payload }) => {
      // call provider specific user identify method
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third-party
      return !!Hotjar.isReady()
    }
  }
}

export default hotjarAnalytics
