export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum Rounded {
  SMALL,
  NORMAL,
  LARGE,
  FULL,
}

export const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PRIMARY]: 'bg-primary-600 hover:bg-primary-500 text-white',
  [Variant.SECONDARY]: 'bg-white hover:bg-gray-200 text-black',
};

export const ROUNDED_MAPS: Record<Rounded, string> = {
  [Rounded.SMALL]: 'rounded',
  [Rounded.NORMAL]: 'rounded-md',
  [Rounded.LARGE]: 'rounded-lg',
  [Rounded.FULL]: 'rounded-full',
};
