import { Type } from "@angular/core";
import { APIService } from "./api.service";
import { ENDPOINT_METADATA } from "./api.decorator";

export class APIResolver {
  private static readonly cache: Map<Type<any>, APIService> = new Map()

  private static getEndpoint(entity: Type<any>){
    return Reflect.getMetadata(ENDPOINT_METADATA, entity)
  }

  public static get(entity: Type<any>){
    if(!this.cache.has(entity)){
      this.cache.set(entity, APIService.create(this.getEndpoint(entity)))
    }

    return this.cache.get(entity)
  }
}
