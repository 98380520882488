import { CommonModule } from '@angular/common';
import { Component, isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Analytics } from 'analytics';
import { CentralState } from 'src/app/store/central';
import { CentralCustomization } from 'src/entities/central-customization.entity';
import googleAnalytics from '@analytics/google-analytics'
import hotjarAnalytics from './hotjarAnalyticsPlugins'

import * as CookieConsent from 'vanilla-cookieconsent';

@Component({
  selector: 'app-cookieconsent',
  templateUrl: 'cookieconsent.component.html',
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class CookieConsentComponent {
  @SelectSnapshot(CentralState.getCustomization)
  customization!: CentralCustomization | undefined

  constructor(private readonly router: Router, private readonly translocoService: TranslocoService) {
    const analyticsPlugins = [];
    const analyticsCookies: CookieConsent.CookieItem[] = []

    if(this.customization?.integrations?.gaTrackingId){
      analyticsPlugins.push(
        googleAnalytics({
          measurementIds: [this.customization.integrations.gaTrackingId],
        }),
      );

      analyticsCookies.push({
        name: /^_ga/, // regex: match all cookies starting with '_ga'
      }),

      analyticsCookies.push({
        name: '_gid', // string: exact cookie name
      })
    }

    if(this.customization?.integrations?.hotjarId){
      analyticsPlugins.push(
        hotjarAnalytics({
          siteId: +this.customization.integrations.hotjarId,
          version: 6,
        })
      );

      analyticsCookies.push({
        name: /^_hj/, // regex: match all cookies starting with '_hj'
      })
    }

    const analytics = Analytics({
      plugins: analyticsPlugins,
    })

    if(analyticsCookies.length > 0){
      CookieConsent.run({
        categories: {
          // necessary: {
          //   enabled: true, // this category is enabled by default
          //   readOnly: true, // this category cannot be disabled
          // },
          analytics: {
            enabled: true,
            readOnly: false,

            // Delete specific cookies when the user opts-out of this category
            autoClear: {
              cookies: analyticsCookies
            },
          },
        },
        language: {
          default: 'pt',
          translations: {
            en: '../assets/i18n/cookieconsent/en.json',
            pt: '../assets/i18n/cookieconsent/pt.json',
            es: '../assets/i18n/cookieconsent/es.json',
          },
        },
        onConsent: () => {
          if (
            CookieConsent.acceptedCategory('analytics')
          ) {
            if(!isDevMode()) analytics.plugins.enable(['google-analytics', 'hotjar'])
          } else {
            if(!isDevMode()) analytics.plugins.disable(['google-analytics', 'hotjar'], () => isDevMode() && console.info('Analytics plugins disabled.'));
          }
        },
      })

      CookieConsent.setLanguage(this.translocoService.getActiveLang());

      this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if(CookieConsent.acceptedCategory('analytics') && !isDevMode()){
            analytics.page({
              url: event.urlAfterRedirects,
            });
          }
        }
      });
    }
  }
}
