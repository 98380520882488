import { ViewportScroller } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, Router, RouterModule, RouterStateSnapshot, Scroll, TitleStrategy } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { filter, pairwise } from 'rxjs/operators';
import { CentralCustomization } from 'src/entities/central-customization.entity';
import { pagesRoutes } from './pages/pages.routing';
import { CentralState } from './store/central';

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  @SelectSnapshot(CentralState.getCustomization)
  customization!: CentralCustomization

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    console.log('routerState', routerState)

    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} | ${this.customization.title}`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(pagesRoutes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
  // providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleStrategy }],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');

    this.handleScrollOnNavigation();
  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Related to: https://github.com/angular/angular/issues/26744
   */
  private handleScrollOnNavigation(): void {
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe((e: Scroll[]) => {
        const previous = e[0];
        const current = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a query param change
          if (
            this.getBaseRoute(previous.routerEvent.url) !==
            this.getBaseRoute(current.routerEvent.url)
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
