export const sortIndicators = <T extends { code: string | null, title: string }>(indicators: T[]): T[] => {
  const indicatorsWithCode: T[]  = [];
  const indicatorsWithoutCode: T[] = [];

  for (const indicator of indicators) {
    if (indicator.code) {
      indicatorsWithCode.push(indicator);
    } else {
      indicatorsWithoutCode.push(indicator);
    }
  }

  const sortedIndicatorsWithCode = indicatorsWithCode.sort((a, b) => a.code!.localeCompare(b.code!, undefined, {
    numeric: true,
    sensitivity: 'base',
  })
  );

  const sortedIndicatorsWithoutCode = indicatorsWithoutCode.sort((a, b) => a.title.localeCompare(b.title, undefined, {
    numeric: false,
    sensitivity: 'base',
  })
  );

  const sortedIndicators = [...sortedIndicatorsWithCode, ...sortedIndicatorsWithoutCode];

  return sortedIndicators;
}

export const sortByPosition = <T extends { position: number }>(data: T[]): T[] => {
  return data.sort((a, b) => a.position - b.position);
}
