import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, RouterModule, BreadcrumbModule, ButtonModule, NgOptimizedImage],
  exports: [CarouselComponent],
})
export class CarouselModule {}
