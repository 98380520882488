<header class="sticky top-0 z-20 bg-white shadow" #headerContainer>
  <div class="px-4 py-4 sm:px-20">
    <div class="container flex items-center justify-between mx-auto ">
      <div class="flex items-center space-x-4">
        <a *ngIf="customization && customization.logo" [routerLink]="['/']">
          <!-- <img
            [ngSrc]="customization.logo"
            alt="Logo"
            priority
            fill
            class="h-12"
          > -->
          <img
            [src]="customization.logo"
            alt="Logo"
            class="object-contain h-12"
          >
        </a>
        <div class="hidden max-w-32 sm:block">
          <h1 class="flex flex-col font-light leading-none tracking-wider uppercase text-secondary-700">
            <a [routerLink]="['/']" class="font-bold text-primary-500">{{ customization?.title }}</a>
          </h1>
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <div class="items-center hidden space-x-4 sm:flex">
          <a *ngIf="customization?.button?.visible" [href]="customization?.button?.link" target="_blank" class="flex items-center pr-4 space-x-2 text-xs text-gray-500 uppercase break-words transition-colors duration-200 rounded-full group hover:bg-primary-500/10 hover:text-primary-500">
            <div
            class="p-2 transition-colors duration-200 border-2 rounded-full group-hover:bg-primary-500/20 border-primary-500/10 text-primary-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="py-1 text-left max-w-36">
              <p class="font-bold">{{ customization?.button?.title }}</p>
            </div>
          </a>
          <!-- <button
            class="flex items-center pr-4 space-x-2 text-xs text-gray-500 uppercase break-words transition-colors duration-200 rounded-full group hover:bg-primary-100 hover:text-primary-500"
          >
            <div
              class="p-2 transition-colors duration-200 border-2 rounded-full group-hover:bg-primary-200 border-primary-100 text-primary-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                />
              </svg>
            </div>
            <div class="py-1 text-left">
              <p class="font-bold">Publicações</p>
            </div>
          </button> -->
        </div>
        <a
          type="button"
          [routerLink]="['/search']"
          class="p-2 transition-colors duration-200 border-2 rounded-full hover:bg-primary-500/20 border-primary-500/10 text-primary-500 focus:bg-primary-500/20"
          [attr.aria-label]="'common.search' | transloco"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
          </svg>
        </a>
        <button
          type="button"
          *ngIf="languages && languages.length > 1"
          (click)="isLangSwitchOpened = !isLangSwitchOpened"
          class="p-2 transition-colors duration-200 border-2 rounded-full hover:bg-primary-500/20 border-primary-500/10 text-primary-500 focus:bg-primary-500/20"
          [title]="'glossary.languages' | transloco"
          [attr.aria-label]="'glossary.languages' | transloco"
          cdkOverlayOrigin
          #langButton="cdkOverlayOrigin"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path d="M7.75 2.75a.75.75 0 00-1.5 0v1.258a32.987 32.987 0 00-3.599.278.75.75 0 10.198 1.487A31.545 31.545 0 018.7 5.545 19.381 19.381 0 017 9.56a19.418 19.418 0 01-1.002-2.05.75.75 0 00-1.384.577 20.935 20.935 0 001.492 2.91 19.613 19.613 0 01-3.828 4.154.75.75 0 10.945 1.164A21.116 21.116 0 007 12.331c.095.132.192.262.29.391a.75.75 0 001.194-.91c-.204-.266-.4-.538-.59-.815a20.888 20.888 0 002.333-5.332c.31.031.618.068.924.108a.75.75 0 00.198-1.487 32.832 32.832 0 00-3.599-.278V2.75z" />
            <path fill-rule="evenodd" d="M13 8a.75.75 0 01.671.415l4.25 8.5a.75.75 0 11-1.342.67L15.787 16h-5.573l-.793 1.585a.75.75 0 11-1.342-.67l4.25-8.5A.75.75 0 0113 8zm2.037 6.5L13 10.427 10.964 14.5h4.073z" clip-rule="evenodd" />
          </svg>
        </button>
        <button
          type="button"
          (click)="isMenuOpened = !isMenuOpened"
          class="p-2 transition duration-200 rounded-full bg-primary-500/20 text-primary-500 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white"
          [title]="'navigation.title' | transloco"
          [attr.aria-label]="'navigation.title' | transloco"
          cdkOverlayOrigin
          #navButton="cdkOverlayOrigin"
        >
          <svg
            *ngIf="!isMenuOpened"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>

          <svg
            *ngIf="isMenuOpened"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</header>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="navButton"
  [cdkConnectedOverlayOpen]="isMenuOpened"
  cdkConnectedOverlayBackdropClass="bg-opacity-0"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPositions]="[{
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  }]"
  (backdropClick)="isMenuOpened = !isMenuOpened"
>
  <app-navigation></app-navigation>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="langButtonRef"
  [cdkConnectedOverlayOpen]="isLangSwitchOpened"
  cdkConnectedOverlayBackdropClass="bg-opacity-0"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPositions]="[{
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  }]"
  (backdropClick)="isLangSwitchOpened = !isLangSwitchOpened"

>
  <app-language-switcher></app-language-switcher>
</ng-template>


