<div
  class="fixed inset-0 top-[5rem] sm:top-auto sm:relative z-10 w-full sm:mt-6 tracking-wider lg:max-w-xs"
>
  <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
    <div class="relative flex flex-col p-4 space-y-2 bg-white">
      <button
        *ngFor="let language of languages"
        (click)="handleLanguageChange(language)"
        class="flex items-center px-3 py-2 space-x-2 text-sm text-left transition duration-200 rounded hover:bg-primary-500/10"
        [ngClass]="{'bg-primary-500/10 text-primary-600 font-bold': activeLang === language.initials, 'text-gray-500 font-medium': activeLang !== language.initials}"
      >
        <!-- <img
          class="w-6 h-6"
          [src]="'https://countryflagsapi.com/svg/' + language.flagCode"
          alt="{{ language.name }}"
        /> -->
        <span>{{ language.name }}</span>
      </button>
    </div>
  </div>
</div>
