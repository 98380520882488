import { Component, Input } from '@angular/core';
import { Rounded, ROUNDED_MAPS, Variant, VARIANT_MAPS } from './button.types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent {
  @Input()
  variant: Variant = Variant.PRIMARY;

  @Input()
  label!: string;

  @Input()
  href!: string;

  @Input()
  fullWidth: boolean = false;

  rounded: Rounded = Rounded.NORMAL;

  constructor() {}

  Variant = Variant;

  ROUNDED_MAPS = ROUNDED_MAPS;
  VARIANT_MAPS = VARIANT_MAPS;
}
