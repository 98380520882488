import { Component, OnInit } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState } from 'src/app/store/central';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @ViewSelectSnapshot(CentralState.getCompanyName)
  company!: string;

  constructor() { }


}
