import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState } from 'src/app/store/central';
import { CentralMenu } from 'src/entities/central-menu.entity';
import { Carousel, Central } from 'src/entities/central.entity';
import { Company } from 'src/entities/company.entity';

@Component({
  selector: 'app-central-home',
  templateUrl: './central.component.html',
})
export class CentralHomeComponent implements OnInit {
  @ViewSelectSnapshot(CentralState.getCarousels)
  public carousels!: Carousel[];

  @ViewSelectSnapshot(CentralState.getMenus)
  public centralMenus!: CentralMenu[];

  @SelectSnapshot(CentralState.getCompanyName)
  company!: string;

  @SelectSnapshot(CentralState.getCentralState)
  central!: Central;

  constructor(private readonly title: Title) {}

  ngOnInit() {
    this.title.setTitle((this.central.name ? this.central.name + ' | ' : '') + this.central.companyName);
  }

  getMenuLink(menu: CentralMenu) {
    if (menu.properties?.externalUrl) {
      return menu.properties.externalUrl;
    } else {
      if (menu.children?.length) {
        return `category/${menu.slug}`;
      } else {
        return `category/${menu.slug}/indicator`;
      }
    }
  }
}
