import { inject, Injectable } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Api } from 'src/app/api/api.decorator';
import { APIService } from 'src/app/api/api.service';
import { sortByPosition } from 'src/app/shared/sort-indicators';
import { IndicatorRepository } from 'src/app/store/v2/indicator.repository';
import { Indicator, IndicatorBasic, IndicatorMap } from 'src/entities/indicator.entity';

@Injectable()
export class CentralSearchService {
  @Api(IndicatorMap)
  indicatorMapApi!: APIService<IndicatorMap>

  constructor(private readonly indicatorRepository: IndicatorRepository) { }

  async getIndicatorsMap() {
    const indicators = await this.indicatorMapApi.get<Map<number, Indicator>>()
    const indicatorsMap = new Map<number, Indicator>(indicators)
    const indicatorsArray = sortByPosition(Array.from(indicatorsMap.values()))

    this.indicatorRepository.setStubIndicators(indicatorsArray as IndicatorBasic[])
    this.indicatorRepository.setIndicators(indicatorsArray)

    return indicatorsArray
  }
}


export const indicatorsResolve: ResolveFn<IndicatorMap> = () => {
  return inject(CentralSearchService).getIndicatorsMap();
};
