import 'reflect-metadata'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { isDevMode } from '@angular/core';
import { instrumentAngularRouting } from '@sentry/angular-ivy';

Sentry.init({
  dsn: "https://75ae32f2e36345398d4d5f3e8a2592a5@o492405.ingest.sentry.io/4504695520886784",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  enabled: !isDevMode()
});

const activeTransaction = Sentry.getActiveTransaction();
const bootstrapSpan =
  activeTransaction &&
  activeTransaction.startChild({
    description: "platform-browser-dynamic",
    op: "ui.angular.bootstrap",
  });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
  .finally(() => {
    if (bootstrapSpan) {
      bootstrapSpan.finish();
    }
  });
