import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { firstValueFrom } from "rxjs";
import { EndpointMetadata } from "./api.decorator";

@Injectable({
  providedIn: 'root'
})
export class APIService<T = any> {
  private readonly activeLang: string;
  private static instance: APIService
  private static initialized = false

  private endpointURL: string = ''
  private hasI18n: boolean = true

  public static getInstance(){
    return this.instance
  }

  public static create(metadata: EndpointMetadata){
    const api = new APIService(this.instance.httpClient, this.instance.translocoService)

    api.endpointURL = metadata.url
    api.hasI18n = metadata.hasI18n

    return api
  }

  constructor(private readonly httpClient: HttpClient, private readonly translocoService: TranslocoService){
    this.activeLang = this.translocoService.getActiveLang() === 'pt' ? 'br' : this.translocoService.getActiveLang()
    APIService.instance = this
    APIService.initialized = true
  }

  async get<R = T[]>(url?: string): Promise<R> {
    await this.initialize()

    return await firstValueFrom(this.httpClient.get<R>(`/data/${this.hasI18n ? `${this.activeLang}/` : ''}${url || this.endpointURL}.json`))
  }

  async getOne(id: string | number, url?: string): Promise<T> {
    return await firstValueFrom(this.httpClient.get<T>(`/data/${this.hasI18n ? `${this.activeLang}/` : ''}${url || this.endpointURL}/${url || this.endpointURL}_${id}.json`))
  }

  private async initialize(){
    if(APIService.initialized){
      return Promise.resolve()
    }

    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if(APIService.initialized){
          clearInterval(interval)

          resolve()
        }
      }, 10)
    })
  }
}
