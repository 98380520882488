<a
  *ngIf="href"
  [redirect]="href"
  class="inline-flex items-center justify-center px-5 py-3 text-sm font-medium transition-colors duration-150 border border-transparent xl:text-base"
  [ngClass]="[VARIANT_MAPS[variant], ROUNDED_MAPS[rounded], fullWidth ? 'w-full' : '']"
>
  {{ label }}
</a>
<button
  *ngIf="!href"
  class="inline-flex items-center justify-center px-5 py-3 text-sm font-medium transition-colors duration-150 border border-transparent xl:text-base"
  [ngClass]="[VARIANT_MAPS[variant], ROUNDED_MAPS[rounded], fullWidth ? 'w-full' : '']"
>
  {{ label }}
</button>
