<app-cookieconsent></app-cookieconsent>
<app-header></app-header>
<div class="flex flex-col min-h-screen bg-primary-500/5">
  <div class="flex-1 h-full">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<app-portal-output [target]="'menu'"></app-portal-output>
<app-portal-output [target]="'lang-switch'"></app-portal-output>
