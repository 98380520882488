import { Variant } from "src/app/components/button/button.types";
import { CollectPeriod, Grouper } from "./grouper.entity";
import { Language } from "./languages.entity";
import { Endpoint } from "src/app/api/api.decorator";

@Endpoint('central', false)
export class Central {
  companyName!: string
  logo!: string;
  name!: string | null

  languages!: Language[];
  periods!: CentralPeriod[];
}

export interface CentralPeriod {
  /**
 * ID de CollectPeriod (CollectPeriod.id)
 */
  id: number;

  /**
   * Nome dado ao período (CollectPeriod.grouper.name)
   */
  name: string;

  /**
   * ID de Period (CollectPeriod.grouper)
   */
  periodRef: number;
}

@Endpoint('carousels')
export class Carousel {
  id!: number;
  image!: string | null;
  imageSrc!: string | null;

  title!: string;
  description!: string;
  actions!: Action[];

  active!: boolean;
  createdAt!: Date;
  updatedAt!: Date;
}

export interface Action {
  id: number;
  variant: Variant;
  label: string;
  url: string;
}

export interface CentralEntityMetadata {
  header: string;
  footer: string;
  alternativeTitle?: string;
}
