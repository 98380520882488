<nav
  aria-label="Breadcrumb"
  class="px-4 text-sm bg-white rounded-lg shadow-2xl shadow-primary-700/20 text-slate-800 w-fit"
>
  <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="flex flex-wrap list-none gap-x-4">
    <ng-container *ngFor="let breadcrumb of (breadcrumbs$ | async); index as index; let first = first; let last = last">
      <li
        class="last:opacity-50 last:pointer-events-none flex items-center after:content-['\203A'] after:block last:after:hidden group"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <a
          [routerLink]="[breadcrumb.url]"
          class="py-2 pr-4 transition-all hover:text-primary-600"
          [attr.rel]="!last ? 'previous' : null"
          itemprop="item"
        >
          <p class="line-clamp-1" itemprop="name">{{ breadcrumb.label.startsWith('i18n|') ? (breadcrumb.label.replace('i18n|', '') | transloco) : breadcrumb.label }}</p>
          <!-- <span
            class="md:hidden"
            aria-hidden="true"
          >Voltar</span> -->
          <meta itemprop="position" [content]="index + 1" />
        </a>
      </li>
    </ng-container>
  </ol>
</nav>

<!-- <nav
  class="flex"
  aria-label="Breadcrumb"
>
  <ol class="inline-flex items-center space-x-1 md:space-x-3">
    <li *ngFor="let breadcrumb of breadcrumbs; index as index" [ngClass]="{'inline-flex items-center': index === 0}">
      <a
        *ngIf="index === 0; else notFirstBreadcrumb"
        [routerLink]="breadcrumb.url"
        class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
      >
        <svg
          class="w-4 h-4 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
          ></path>
        </svg>
        {{ breadcrumb.label }}
      </a>
      <ng-template #notFirstBreadcrumb>
        <div class="flex items-center">
          <ng-container *ngIf="index !== breadcrumbs.length - 1; else lastBreadcrumb">
            <svg
              class="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <a
              [routerLink]="breadcrumb.url"
              class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
            >{{ breadcrumb.label }}</a>
          </ng-container>
          <ng-template #lastBreadcrumb>
            <svg
              class="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">{{ breadcrumb.label }}</span>
          </ng-template>
        </div>
      </ng-template>
    </li> -->
    <!-- <li *ngFor="let breadcrumb of breadcrumbs; index as index">
      <div *ngIf="index === 0">
        <a
          class="whitespace-nowrap"
          [routerLink]="breadcrumb.url"
        >
          {{ breadcrumb.label }}
        </a>
      </div>
      <div
        *ngIf="index !== 0"
        class="flex items-center ml-1 whitespace-nowrap"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <a
          *ngIf="index !== breadcrumbs.length - 1;"
          class="ml-1 capitalize text-primary-500 "
          [routerLink]="breadcrumb.url"
        >{{ breadcrumb.label }}</a>
        <span
          *ngIf="index === breadcrumbs.length - 1"
          class="ml-1 font-semibold text-gray-500 capitalize"
        >{{ breadcrumb.label }}</span>
      </div>
    </li> -->
  <!-- </ol>
</nav> -->
