<div class="flex items-center justify-center h-full text-primary-500">
  <svg
    id="eb208ada-0674-4b2f-849d-505f988f9e2e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="665.76"
    height="482.89"
    viewBox="0 0 865.76 682.89"
    fill="currentColor"
  >
    <defs>
      <clipPath id="bb7dec4e-06ae-4f27-9d03-194882854bed">
        <path
          id="a3e254d5-4d86-4e79-bf89-0fe5680cd90c"
          data-name="SVGID"
          d="M112.53,391.14s25.94-21.35,34.18-11.32c0,0,6.16,3.84-7.35,11.32,0,0-70,21.76-8.26,117.32l41.5-22.52s71.75-34,54.78-62.6c0,0-16.41-53,11.59-56.15,0,0,17,0,12.09,23.95,0,0-10.44,37.14-5.5,52,0,0,18.8-10.71,27.8-17.16,0,0,79.29-64.44,84-65.1s6.59-8.19,6.59-8.19l-11.26-30.92-38.76-11.53s-78.27-9.37-116.88,7.67c0,0,13.75,4.75,6.93,9.32a9.67,9.67,0,0,1-5.54,1.55C187.15,328.59,134.42,331.15,112.53,391.14Z"
          fill="#939598"
        />
      </clipPath>
    </defs>
    <path
      d="M141.65,665S41.09,583.79,52.92,498.55,161.37,484.34,185,407.22,121.47,229.33,200.57,159s152.06-3.4,201.35-17.61S447.27,17.55,559.66,15.52s205.56,67,163.91,264.86,86.5,220.2,104.25,291.23-26,95.81-26,95.81Z"
    />
    <path
      d="M141.65,665S41.09,583.79,52.92,498.55,161.37,484.34,185,407.22,121.47,229.33,200.57,159s152.06-3.4,201.35-17.61S447.27,17.55,559.66,15.52s205.56,67,163.91,264.86,86.5,220.2,104.25,291.23-26,95.81-26,95.81Z"
      fill="#fff"
      opacity="0.7"
    />
    <path
      id="e5460902-32fb-4f5b-afd6-7b750d57ead3"
      data-name="SVGID"
      d="M112.53,391.14s25.94-21.35,34.18-11.32c0,0,6.16,3.84-7.35,11.32,0,0-70,21.76-8.26,117.32l41.5-22.52s71.75-34,54.78-62.6c0,0-16.41-53,11.59-56.15,0,0,17,0,12.09,23.95,0,0-10.44,37.14-5.5,52,0,0,18.8-10.71,27.8-17.16,0,0,79.29-64.44,84-65.1s6.59-8.19,6.59-8.19l-11.26-30.92-38.76-11.53s-78.27-9.37-116.88,7.67c0,0,13.75,4.75,6.93,9.32a9.67,9.67,0,0,1-5.54,1.55C187.15,328.59,134.42,331.15,112.53,391.14Z"
      fill="#939598"
    />
    <g clip-path="url(#bb7dec4e-06ae-4f27-9d03-194882854bed)">
      <path
        d="M131.1,508.46s-13.88-76.34,41.5-130.7c0,0,58-56.57,134.39-53.54l56.92,28.44-9.06,34.55L237.13,463.54S143.5,511.21,131.1,508.46Z"
        fill="#bcbec0"
      />
    </g>
    <path
      d="M135.47,367.19s30.89-33,96.83-28"
      fill="none"
      stroke="#a7a9ac"
      stroke-miterlimit="10"
      stroke-width="2.29"
    />
    <path
      d="M180.76,357.7s16.71-17.32,47.07-16.48"
      fill="none"
      stroke="#a7a9ac"
      stroke-miterlimit="10"
      stroke-width="2.29"
    />
    <ellipse
      cx="199.71"
      cy="400.2"
      rx="11.81"
      ry="25.74"
      fill="#d1d3d4"
    />
    <path
      d="M282.45,367.19s-7.47-17.85,13.07-26Z"
      fill="#d1d3d4"
    />
    <circle
      cx="101.2"
      cy="379.82"
      r="11.33"
      fill="#2f2e41"
    />
    <path
      d="M112.53,453.25c3.43,0,1-34.64,45.87-59.18"
      fill="none"
      stroke="#a7a9ac"
      stroke-miterlimit="10"
      stroke-width="2.29"
    />
    <path
      d="M158.4,324.22v129H73.58L91,425.18a17.42,17.42,0,0,0-4.51-23.24h0a17.41,17.41,0,0,0-25,4.71L26,462.28a17.62,17.62,0,0,0,14.85,27.1h119v56a17.3,17.3,0,0,0,17.3,17.3h0a17.29,17.29,0,0,0,17.3-17.3v-56h26.57A17.91,17.91,0,0,0,239,471.46h0a17.92,17.92,0,0,0-17.91-17.92H194.9l.41-145.39a30.94,30.94,0,0,0-30.95-30.95h0c-10.72,0-23.54,4.95-29.18,14.06l-25.05,42.59a16.34,16.34,0,0,0,5.39,22.76h0a16.35,16.35,0,0,0,21.31-4Z"
    />
    <path
      d="M158.4,324.22v129H73.58L91,425.18a17.42,17.42,0,0,0-4.51-23.24h0a17.41,17.41,0,0,0-25,4.71L26,462.28a17.62,17.62,0,0,0,14.85,27.1h119v56a17.3,17.3,0,0,0,17.3,17.3h0a17.29,17.29,0,0,0,17.3-17.3v-56h26.57A17.91,17.91,0,0,0,239,471.46h0a17.92,17.92,0,0,0-17.91-17.92H194.9l.41-145.39a30.94,30.94,0,0,0-30.95-30.95h0c-10.72,0-23.54,4.95-29.18,14.06l-25.05,42.59a16.34,16.34,0,0,0,5.39,22.76h0a16.35,16.35,0,0,0,21.31-4Z"
      fill="#fff"
      opacity="0.36"
    />
    <path
      d="M785.51,460.84s8.55-8.75,13.89,4.06c0,0,3.92,9.26,0,21.73,0,0-1.07,4.62,11.75,10.32,0,0,16.38,5.7,19.59,9.26,0,0,16.62-25.39,10.32-57.65a30.51,30.51,0,0,0-8.78-16.25,52,52,0,0,0-19.84-12,2.54,2.54,0,0,1-1.81-2.65c.22-1.61,2.09-3.33,9.78-2.29,0,0,4.47,1.06,10.23,2.92,0,0-9.26-30.69-52.51-32.75H769a4.22,4.22,0,0,1-3.37-6.76c.27-.34,1.34-.72,1-1.06-3.71-3.3-23.88-2.89-23.88-2.89v47.92s6.14-2.5,9.4,3.15a9.5,9.5,0,0,1,1,2.63c.64,2.71,1.91,9.85,2.38,25.23l16,.31S780.39,453.86,785.51,460.84Z"
      fill="#d1d3d4"
    />
    <ellipse
      cx="774.45"
      cy="432.81"
      rx="7.49"
      ry="16.41"
      transform="translate(-51.52 108.98) rotate(-7.79)"
      fill="#bcbec0"
    />
    <ellipse
      cx="796.02"
      cy="439.52"
      rx="4.67"
      ry="11.05"
      transform="translate(-72.63 170.68) rotate(-11.71)"
      fill="#bcbec0"
    />
    <ellipse
      cx="744.33"
      cy="383.16"
      rx="11.05"
      ry="4.06"
      transform="translate(-23.12 48.7) rotate(-3.69)"
      fill="#bcbec0"
    />
    <path
      d="M742.35,395.36a61.29,61.29,0,0,1,7.6,1.12c1.25.25,2.5.52,3.73.84s2.48.6,3.69,1c2.46.68,4.86,1.55,7.26,2.4,1.19.45,2.36.95,3.55,1.43l1.77.72,1.73.81a103.1,103.1,0,0,1,25.45,16.75c1.87,1.73,3.64,3.57,5.46,5.35s3.34,3.82,5,5.72a51.41,51.41,0,0,1,4.61,6.13c1.41,2.12,2.68,4.32,3.91,6.54,2.44,4.45,4.63,9,6.66,13.65s3.79,9.38,5.46,14.14A221.78,221.78,0,0,0,815,444.81c-1.3-2.14-2.62-4.28-4-6.34a49.34,49.34,0,0,0-4.6-5.87c-1.69-1.85-3.33-3.76-5.07-5.55s-3.6-3.48-5.46-5.15a105,105,0,0,0-25-16.32l-1.69-.81-1.73-.72c-1.16-.48-2.3-1-3.47-1.43l-3.52-1.27c-1.17-.45-2.38-.76-3.57-1.15A84.82,84.82,0,0,0,742.24,397Z"
      fill="#a7a9ac"
    />
    <path
      d="M802.68,425.8a41.7,41.7,0,0,1,11,4.45,49.26,49.26,0,0,1,9.5,7,48.17,48.17,0,0,1,7.54,9,48.77,48.77,0,0,1,2.93,5.05,25.13,25.13,0,0,1,1.18,2.65l1.1,2.68a66,66,0,0,0-6.21-9.66,50.44,50.44,0,0,0-7.87-8.19,51.49,51.49,0,0,0-9.32-6.39,53.81,53.81,0,0,0-5.08-2.44A44.47,44.47,0,0,0,802.2,428Z"
      fill="#a7a9ac"
    />
    <path
      d="M814.51,415.18c-5.81.67-3.27,4.37-3.27,4.37s-7.85-5.27,1.2-6.65a21.15,21.15,0,0,1,14.14,4.18S820.23,414.71,814.51,415.18Z"
      fill="#bcbec0"
    />
    <path
      d="M744.11,395.79a60.08,60.08,0,0,1,8.84-2.53A86.9,86.9,0,0,1,762,392a82.45,82.45,0,0,1,18.31.49c1.52.13,3,.5,4.52.75a45,45,0,0,1,4.47,1l4.43,1.17c1.46.43,2.88,1,4.32,1.48,2.91.93,5.64,2.28,8.43,3.48l1,.47c.34.17.67.37,1,.55l2,1.09q2,1.1,4,2.22c-1.39-.6-2.78-1.2-4.16-1.82l-2.07-.92c-.34-.15-.68-.31-1-.45l-1.07-.38c-2.84-1-5.61-2.2-8.53-2.94-1.44-.4-2.87-.86-4.32-1.24l-4.39-1c-1.45-.37-2.94-.57-4.41-.81s-2.95-.54-4.44-.64l-4.47-.46-4.48-.19c-3-.07-6,0-9,.17a81.08,81.08,0,0,0-17.62,3Z"
      fill="#a7a9ac"
    />
    <path
      d="M742.72,422.79l-.42-6.6s14.27-4.38,15.65,17.86,0,19.79,0,19.79l-2.46,0-1.59-21S753.59,419.77,742.72,422.79Z"
      fill="#bcbec0"
    />
    <path
      d="M782.72,457.94s4.42-7.65,11.39-3.79a10.73,10.73,0,0,1,3.68,3.44c1.6,2.42,3.65,7,3.18,14.35,0,0-2.46-22.2-15.46-11.1Z"
      fill="#bcbec0"
    />
    <path
      d="M799.4,486.63s5.09,5.91,17.93,8.65c0,0,9.35,2.66,12.29,5.22,0,0,10.63-16.68,12.86-38a81.27,81.27,0,0,1-11.74,43.71s-4.5-4.55-14.21-7.26C816.53,499,800.35,494.72,799.4,486.63Z"
      fill="#bcbec0"
    />
    <path
      d="M766.87,425.86s1.93-6.74,6.39-6.31c0,0,5.35.88,6.53,4.33,0,0-3.28-8.29-7.57-7.32C772.22,416.56,768.47,416.19,766.87,425.86Z"
      fill="#a7a9ac"
    />
    <path
      d="M791,434.88s2.81-7.1,7.67-2.07c0,0-1.58-4.31-4.84-4.11C793.78,428.7,790.44,429.3,791,434.88Z"
      fill="#a7a9ac"
    />
    <path
      d="M385.92,559.52s8.13,3.58,29.33,2.76V527.1L400,526.33s-4.46,3.63-7.16,4.5C392.87,530.83,393.42,549.37,385.92,559.52Z"
    />
    <path
      d="M385.92,559.52s8.13,3.58,29.33,2.76V527.1L400,526.33s-4.46,3.63-7.16,4.5C392.87,530.83,393.42,549.37,385.92,559.52Z"
      fill="#fff"
      opacity="0.5"
    />
    <circle
      cx="636.31"
      cy="347.89"
      r="32.54"
    />
    <circle
      cx="636.31"
      cy="347.89"
      r="32.54"
      fill="#fff"
      opacity="0.9"
    />
    <path
      d="M519.35,227.75l9.89.59,1,37.47-9.67-.75S530,237.25,519.35,227.75Z"
      fill="#2f2e41"
    />
    <path
      d="M516.86,224.54s5.49-4.12,7.41-6.87c0,0,1.92-3.76,9.06-3.66l9.89-4.85s12.63-5.22,18.67,7.69L544,216.33a35.71,35.71,0,0,0-17.3,12.32h-5.21S520.7,226.73,516.86,224.54Z"
      fill="#f7a48b"
    />
    <path
      d="M520.6,227.39l6.63-6s5-5.23,8.57-4.49c0,0,18.38-6.82,21.15-.1l-29.05,11.9h-6.37Z"
      fill="#f9b499"
    />
    <path
      d="M575.37,223.65l-.47-.39c-.07,0-6.62-5.34-17.88-3.78l-.52.07,0-.52c-.06-.7-1.42-17.17,5.9-24.28a11.56,11.56,0,0,1,9.9-3.05,12.35,12.35,0,0,1,8,6.86c2.65,5.89,1.11,14.16-4.56,24.55Zm-14.16-5.46a24.14,24.14,0,0,1,13.84,3.94c5.29-9.86,6.73-17.65,4.27-23.15a11.6,11.6,0,0,0-7.24-6.3,10.6,10.6,0,0,0-9,2.79c-6.27,6.1-5.8,20.08-5.63,22.95A32.07,32.07,0,0,1,561.21,218.19Z"
      fill="#2f2e41"
    />
    <ellipse
      cx="578.14"
      cy="318.59"
      rx="33.36"
      ry="10.74"
      transform="translate(-64.96 169.71) rotate(-15.82)"
      fill="#2f2e41"
    />
    <path d="M484.39,296.74s31.3,13.74,55,54.12L470,354l-6.82-51Z" />
    <path
      d="M484.39,296.74s31.3,13.74,55,54.12L470,354l-6.82-51Z"
      opacity="0.8"
    />
    <g opacity="0.36">
      <path
        d="M484.39,296.74s31.3,13.74,55,54.12L470,354l-6.82-51Z"
        fill="#fff"
      />
      <path
        d="M484.39,296.74s31.3,13.74,55,54.12L470,354l-6.82-51Z"
        fill="#fff"
        opacity="0.8"
      />
    </g>
    <path
      d="M412.24,333.08s-18.17-2.09-13.33-12c0,0,2.48-4.48,13.22-5.75Z"
      fill="#2f2e41"
    />
    <path
      d="M467.81,317.88s4.17,0,6.8,1.76l-4.31,12Z"
      fill="#2f2e41"
    />
    <path
      d="M143.55,275.07s-28.31-56.15,57.08-79.65c0,0,10.08,39.13,48,33.23,0,0,12-8.15-15.83-26.45,0,0-15.35-7.53-21.59-9.31,0,0,41.27-14.61,60.21,9.31a48.21,48.21,0,0,1,7,20s2.49,16.15,11.81,17.34c0,0,12.18,1.41,4-27.12,0,0-11-15.85-15.83-19.55,0,0,40.24.37,43.15,24,0,0-6.69,41.15,12.5,49,0,0,17.27-.45,2.4-38.3,0,0-2.88-10.52-8.16-15.07,0,0,47.35,34.52,54.62,107.81l2,25.32-20.94,7.09s-47.83-8.16-68.39-11.44c0,0-11.89-1.89-16.21-12.44,0,0,25,0,33.59-12.56a6.92,6.92,0,0,0,0-8.08c-2.25-3-6.91-5.77-16.28-.95,0,0-62.36,42.23-82.51,8.16,0,0,70-13.91,62.84-34.54,0,0-3.36-9-28.78,4.14,0,0-77.12,47.91-96.06-3.16,0,0,29-3.08,33.6-16.69a5.86,5.86,0,0,0-4.94-7.79c-3.19-.33-8.16.43-15.89,3.88C164.84,261.19,151.06,268.36,143.55,275.07Z"
      fill="#d1d3d4"
    />
    <ellipse
      cx="449.11"
      cy="233.57"
      rx="46.27"
      ry="79.14"
      transform="translate(-33.84 81.85) rotate(-10.04)"
      fill="#f9b499"
    />
    <path
      d="M363.91,285S356.26,305,370,314.86a14.64,14.64,0,0,0,6.45,2.53c6.7,1,22.72,1.69,35.82-9.93l-.38-37.6s-8.37,8.75-21.57-7.72c0,0-4.05-7.23-4.61-10.34C385.68,251.8,363.91,274.79,363.91,285Z"
    />
    <path
      d="M363.91,285S356.26,305,370,314.86a14.64,14.64,0,0,0,6.45,2.53c6.7,1,22.72,1.69,35.82-9.93l-.38-37.6s-8.37,8.75-21.57-7.72c0,0-4.05-7.23-4.61-10.34C385.68,251.8,363.91,274.79,363.91,285Z"
      opacity="0.2"
    />
    <path
      d="M400.46,271s-3.6,9.53-17.6,17.77c0,0,14-4.67,21.42-14.28a38.48,38.48,0,0,1-10.71,20.32s10.16,4.67,18.67,2.75l-.38-27.72S407.47,274.19,400.46,271Z"
      fill="#2f2e41"
    />
    <path
      d="M464.27,154.92s3.6.24,4.82,2.38c0,0,7.35-8.11,12.25-.72a19.57,19.57,0,0,1,2.43,5.91s14.06-.56,0,11.95c0,0-3.94-3.32-13-2.59,0,0-11,2.49-20.72,1.61C450.06,173.46,446.13,153.47,464.27,154.92Z"
      fill="#2f2e41"
    />
    <path
      d="M398.06,166.65s0-5.64,7-8.16c0,0,5-.85,8.85,1.5l-5.66,6.66Z"
      fill="#2f2e41"
    />
    <path
      d="M412.24,333.08l-.38-63.22s18.16,48.38,54.63,40.75L471.1,336s-.8,17.08-1.46,24.85c0,0-11.78-26.27-23.67-26.31Z"
      fill="#f9b499"
    />
    <path
      d="M411.86,269.86s3.57,51.5,58.44,61.75l-3.81-21S433.86,323.05,411.86,269.86Z"
      fill="#f7a48b"
    />
    <path
      d="M418.56,527.27,353.89,524l7.89-61c-9,14.41-33,36.57-33,36.57l45.34-4C360.48,506.68,363.36,524,363.36,524c1.24,31.71,18.54,38.3,18.54,38.3-10.71,7.83-38.31,7.83-38.31,7.83l-87.32-3.71c-23.06-4.53-25.12-19.77-25.12-19.77-8.24-21,19.77-57.25,19.77-57.25l54.69-85.94a115.26,115.26,0,0,0,8.23-15.18c16.69-37.87,50.07-35.6,50.07-35.6L395.7,336c-1.75-8.18,3.21-14.9,3.21-14.9s-7.83,11.55,26.12,12.54l20.94.9s8.41-1.38,17.27,13.5l6.4,12.81L471.1,336l-.8-4.39,4.31-12s10.4-2.29,13.14,21.05c0,0,51.9-1.1,70.57,25.26l-12.08-38.44s9.06,11.12,37.07-.83c3.71,0,18.53-7,26.77-16.47l24.3,70L533.06,546.55Z"
    />
    <path
      d="M361.78,463,391.37,426s-23.62,65.22-34.05,71.53Z"
      opacity="0.2"
    />
    <path
      d="M381.9,562.28s10.24-1.17,11-31.45c0,0-20.73,8.21-27.33,8.48C365.54,539.31,369.1,555.42,381.9,562.28Z"
      fill="#2f2e41"
    />
    <path
      d="M374.07,495.56v3.29l-7.73,6.5S371,498,374.07,495.56Z"
      fill="#2f2e41"
    />
    <path
      d="M516.86,268.47s17.85,23.34,34.32,22.24c0,0,15.15,16.71,17.46,40.9,0,0,25.53-8.07,31.81-13a104.14,104.14,0,0,1-11.65-35S585.23,266,583,264.35L560,273.14l-37.62-11.26S520.43,266.82,516.86,268.47Z"
      fill="#f9b499"
    />
    <path
      d="M395.36,339.22s-4.86,27.88,13.95,48c0,0,8.36-25.94,36-44.43,0,0-18.48,5.86-37,28.39C408.33,371.17,396.82,361.69,395.36,339.22Z"
      opacity="0.2"
    />
    <path
      d="M485.51,357.7s-8.17-23.76-2.46-33a39.2,39.2,0,0,1,4.7,15.95Z"
      opacity="0.2"
    />
    <circle
      cx="463.22"
      cy="367.19"
      r="3.98"
      opacity="0.2"
    />
    <polygon
      points="566.07 354.9 491.05 393.98 491.05 603.75 566.07 565.37 566.07 354.9"
      fill="#e6e7e8"
    />
    <polygon points="641.48 603.75 566.07 565.37 566.07 354.9 641.48 395.35 641.48 603.75" />
    <polygon
      points="641.48 603.75 566.07 565.37 566.07 354.9 641.48 395.35 641.48 603.75"
      fill="#fff"
      opacity="0.8"
    />
    <polygon
      points="641.48 395.35 705.23 363.21 705.23 546.55 641.48 603.75 641.48 395.35"
      fill="#e6e7e8"
    />
    <path
      d="M706,324.22v129H621.18l17.44-28.07a17.42,17.42,0,0,0-4.51-23.24h0a17.41,17.41,0,0,0-25,4.71l-35.51,55.63a17.62,17.62,0,0,0,14.85,27.1h119v56a17.3,17.3,0,0,0,17.3,17.3h0a17.3,17.3,0,0,0,17.3-17.3v-56l29.42.56A17.91,17.91,0,0,0,789.43,472h0a17.92,17.92,0,0,0-17.92-17.92l-29-.57.41-145.39A30.94,30.94,0,0,0,712,277.2h0c-10.72,0-23.54,4.95-29.18,14.06l-25,42.59a16.34,16.34,0,0,0,5.39,22.76h0a16.35,16.35,0,0,0,21.31-4Z"
    />
    <path
      d="M706,324.22v129H621.18l17.44-28.07a17.42,17.42,0,0,0-4.51-23.24h0a17.41,17.41,0,0,0-25,4.71l-35.51,55.63a17.62,17.62,0,0,0,14.85,27.1h119v56a17.3,17.3,0,0,0,17.3,17.3h0a17.3,17.3,0,0,0,17.3-17.3v-56l29.42.56A17.91,17.91,0,0,0,789.43,472h0a17.92,17.92,0,0,0-17.92-17.92l-29-.57.41-145.39A30.94,30.94,0,0,0,712,277.2h0c-10.72,0-23.54,4.95-29.18,14.06l-25,42.59a16.34,16.34,0,0,0,5.39,22.76h0a16.35,16.35,0,0,0,21.31-4Z"
      fill="#fff"
      opacity="0.36"
    />
    <circle
      cx="648.8"
      cy="379.82"
      r="11.33"
      fill="#2f2e41"
    />
    <path
      d="M558.65,275.32s-30.13,4.63-34.24-17.18a30.07,30.07,0,0,0-.18-24.57,4.36,4.36,0,0,1,2.51-4.92s6.59-10.92,17.3-12.32l18.53.52Z"
    />
    <path d="M464.25,227.51s-27.44,2.06-26.34,18.57c0,0-2.5,14.74,28,19.73Z" />
    <path
      d="M546.34,365.52l-1-.18,9.48-52.72c2.24-17.68,10.68-37.55,10.77-37.75l.92.39c-.09.2-8.47,19.95-10.71,37.51Z"
      fill="#2f2e41"
    />
    <path
      d="M491.3,303.41l-.51-.86c.11-.07,11.66-6.94,24.61-9.22a45.39,45.39,0,0,1,14.18-.12c8.57,1.21,37.06,3.26,44.06-20.88l1,.27c-7.22,24.93-36.39,22.84-45.15,21.6a44.62,44.62,0,0,0-13.87.12C502.8,296.56,491.42,303.34,491.3,303.41Z"
      fill="#2f2e41"
    />
    <circle
      cx="566.22"
      cy="628.39"
      r="17.51"
    />
    <path
      d="M147.25,272.07s-17.32-36.78,18.46-61.93c0,0-38.92,21.14-22.16,64.93Z"
      fill="#bcbec0"
    />
    <path
      d="M178.84,257.07s11.34-4,13.91,1.51a4.67,4.67,0,0,1,0,3.82c-1.86,4.25-9.67,15.72-40.64,19.39,0,0,31.16-2,33.91-18.93C186,262.86,187.9,258.72,178.84,257.07Z"
      fill="#bcbec0"
    />
    <path
      d="M208.49,298c-37.48,12.17-56.42-16.25-56.42-16.25s7.05,31.73,50.89,21.73c0,0,14.92-2.82,34.7-12.82,0,0,16.82-9.57,22.76-11.08,0,0-20.62,7.11-40,14.32C220.44,293.94,213.35,296.66,208.49,298Z"
      fill="#bcbec0"
    />
    <path
      d="M264.27,278.54s13.55-6.13,17.54-.33a3.45,3.45,0,0,1,.48,1c.46,1.75.56,6.62-9.15,13.07,0,0,6.08-4.65,3.77-11.5C276.91,280.81,275.35,276.72,264.27,278.54Z"
      fill="#bcbec0"
    />
    <path
      d="M313.89,310.21c-2.79-10.16-17.31-3-17.31-3-11,8.25-32.31,16.36-32.31,16.36-39,15.54-50.2-8.2-50.2-8.2s16.15,18.47,50.2,4.74l30.45-14.62s20.86-10.91,22.51,6.93c0,0,.83,4.81-6.5,6.41A7.76,7.76,0,0,0,313.89,310.21Z"
      fill="#bcbec0"
    />
    <path
      d="M334,265.81s13.51,5.72,12-13.45c0,0-1.37-17.26-12-31.81C334,220.55,352.93,262.9,334,265.81Z"
      fill="#bcbec0"
    />
    <path
      d="M177.47,238.81A330.7,330.7,0,0,1,236.86,244a262,262,0,0,1,57.33,16.67,173.69,173.69,0,0,1,26.43,14.12,130.46,130.46,0,0,1,23.14,19.12c.87.91,1.73,1.82,2.55,2.78s1.67,1.87,2.46,2.86L350,301c.41.48.78,1,1.15,1.51l2.19,3q4.33,6.1,8.4,12.39c2.69,4.2,5.28,8.48,7.66,12.89a75.05,75.05,0,0,1,6.08,13.86l-2.21.63A72.47,72.47,0,0,0,367.3,332c-2.36-4.32-4.95-8.54-7.63-12.7S354.22,311,351.36,307l-2.17-3c-.38-.51-.7-1-1.1-1.45l-1.17-1.42c-.76-1-1.57-1.88-2.39-2.8s-1.64-1.83-2.49-2.72a130.3,130.3,0,0,0-22.63-18.92,173.46,173.46,0,0,0-26-14.15c-18.1-8-37.3-13.47-56.76-17.29q-14.61-2.85-29.44-4.44C197.33,239.68,187.41,238.93,177.47,238.81Z"
      fill="#a7a9ac"
    />
    <path
      d="M237.13,279.62a99.49,99.49,0,0,1,19.64-7.87,109.31,109.31,0,0,1,20.87-3.93,86.45,86.45,0,0,1,21.26.69A65.47,65.47,0,0,1,319,275.07a88.5,88.5,0,0,0-20.36-4.78c-.86-.11-1.73-.21-2.6-.26l-2.59-.2c-1.74-.07-3.48-.17-5.22-.15a97.83,97.83,0,0,0-10.41.42,138.94,138.94,0,0,0-20.62,3.38A163.08,163.08,0,0,0,237.13,279.62Z"
      fill="#a7a9ac"
    />
    <path
      d="M260.42,233.57a1.69,1.69,0,0,1,.83.5,7.6,7.6,0,0,1,.68.71c.44.49.83,1,1.23,1.51l2.35,3.12c1.55,2.08,3.13,4.14,4.8,6.11a58.34,58.34,0,0,0,5.32,5.56,32.63,32.63,0,0,0,6.08,4.46l-1.18,2a34.65,34.65,0,0,1-6.1-5.19,60.2,60.2,0,0,1-5-6.08c-1.54-2.12-3-4.3-4.37-6.49l-2.11-3.28c-.36-.54-.72-1.08-1.11-1.59a8.81,8.81,0,0,0-.62-.75A1.67,1.67,0,0,0,260.42,233.57Z"
      fill="#a7a9ac"
    />
    <path
      d="M216.71,257.07a128.75,128.75,0,0,1,12.69-3.51c2.14-.48,4.3-.9,6.46-1.31s4.34-.73,6.53-1a93.84,93.84,0,0,1,13.2-.91,41.43,41.43,0,0,1,13.08,2c-4.41-.23-8.72-.13-13.05.07s-8.65.52-13,1-8.65,1-13,1.66S221,256.38,216.71,257.07Z"
      fill="#a7a9ac"
    />
    <ellipse
      cx="197.01"
      cy="221.33"
      rx="7.27"
      ry="15.73"
      transform="translate(-98.09 248.02) rotate(-53.49)"
      fill="#bcbec0"
    />
    <ellipse
      cx="177.24"
      cy="226.14"
      rx="5.22"
      ry="9.63"
      transform="translate(-105.42 277.67) rotate(-62.24)"
      fill="#bcbec0"
    />
    <ellipse
      cx="227.38"
      cy="269.99"
      rx="26.32"
      ry="9.88"
      transform="translate(-64.56 71.57) rotate(-15.69)"
      fill="#bcbec0"
    />
    <path
      d="M320.29,368.77s10.09-15.08,15.85-20.82c0,0-32.67-5-40.62-6.73Z"
      fill="#2f2e41"
    />
    <path
      d="M713.3,582.59c-31,0-44.64-15.24-44.82-15.44q-5.43-5.58-9.4-10.48a112.5,112.5,0,0,1-14,10.57c-21.2,9.33-39,10.74-52.9,4.23-16.72-7.81-21.74-24.61-21.79-24.78l.93-.27c0,.17,5,16.56,21.28,24.19,13.66,6.38,31.16,5,52-4.22a113.34,113.34,0,0,0,13.85-10.5c-7.16-9-10.43-16.23-9.74-21.49a8.63,8.63,0,0,1,4.41-6.53,11.64,11.64,0,0,1,11.78.13c4.25,2.46,6.43,5.62,6.49,9.4.1,6.2-5.53,13.05-11.61,18.62q3.94,4.88,9.4,10.48c.25.29,23.88,26.52,79.81,9.24a68.13,68.13,0,0,0,6.42-2.35c13.92-5.93,60.33-25.2,72.86-24.16l-.08,1c-12.64-1-62.53,19.88-72.41,24.08a67.19,67.19,0,0,1-6.5,2.39C735.36,581,723.4,582.59,713.3,582.59Zm-54.49-55.3a10.09,10.09,0,0,0-5.19,1.41,7.68,7.68,0,0,0-3.94,5.82c-.66,5,2.52,12,9.49,20.72,5.91-5.42,11.36-12,11.27-17.83-.05-3.41-2.08-6.3-6-8.58A11.25,11.25,0,0,0,658.81,527.29Z"
    />
    <g opacity="0.25">
      <path
        d="M713.3,582.59c-31,0-44.64-15.24-44.82-15.44q-5.43-5.58-9.4-10.48a112.5,112.5,0,0,1-14,10.57c-21.2,9.33-39,10.74-52.9,4.23-16.72-7.81-21.74-24.61-21.79-24.78l.93-.27c0,.17,5,16.56,21.28,24.19,13.66,6.38,31.16,5,52-4.22a113.34,113.34,0,0,0,13.85-10.5c-7.16-9-10.43-16.23-9.74-21.49a8.63,8.63,0,0,1,4.41-6.53,11.64,11.64,0,0,1,11.78.13c4.25,2.46,6.43,5.62,6.49,9.4.1,6.2-5.53,13.05-11.61,18.62q3.94,4.88,9.4,10.48c.25.29,23.88,26.52,79.81,9.24a68.13,68.13,0,0,0,6.42-2.35c13.92-5.93,60.33-25.2,72.86-24.16l-.08,1c-12.64-1-62.53,19.88-72.41,24.08a67.19,67.19,0,0,1-6.5,2.39C735.36,581,723.4,582.59,713.3,582.59Zm-54.49-55.3a10.09,10.09,0,0,0-5.19,1.41,7.68,7.68,0,0,0-3.94,5.82c-.66,5,2.52,12,9.49,20.72,5.91-5.42,11.36-12,11.27-17.83-.05-3.41-2.08-6.3-6-8.58A11.25,11.25,0,0,0,658.81,527.29Z"
        fill="#fff"
      />
    </g>
    <path
      d="M528.56,515.05s7,3,13-6c0,0,3.35-12.62,13.18-13.41,0,0-2.74,5.72,0,11.21,0,0,3.19,19.5-13.1,11.54C541.65,518.35,535.31,526.86,528.56,515.05Z"
      fill="#2f2e41"
    />
    <path
      d="M394.7,224.9s-30.17-43.16,3.36-58.25a133.24,133.24,0,0,1,18.44-7.54s15.08-8,18.43-11.32c0,0,19.09-14.42,29.34,7.13,0,0-16.88-2-14.21,18.54,0,0,6.18,1,17.51-1,0,0,22.86-6.59,23.48,18.95,0,0-11.95-13.18-32.13-5.56,0,0-15.65,3.84-20.32,12.08,0,0-9.61.82-14.28,13.46,0,0-23.89,30.48-32.4,34,0,0-25,23.89-28,39.55C363.91,285,369.48,255.47,394.7,224.9Z"
    />
    <path
      d="M449.87,189.06A10.66,10.66,0,0,1,437.61,184a9.25,9.25,0,0,1-1.14-7.68,9.43,9.43,0,0,1,5.11-5.58s-5.66,11.37,3.42,14.39a11.36,11.36,0,0,0,2.7.49c2.8.21,9.85.67,11.22.19A41.62,41.62,0,0,0,449.87,189.06Z"
      fill="#2f2e41"
    />
    <g opacity="0.2">
      <path
        d="M400.46,165.46a56,56,0,0,0-2.55,14.09,59.64,59.64,0,0,0,.75,14.25,62,62,0,0,0,3.88,13.74,63.74,63.74,0,0,0,3,6.52l.39.81.45.77.91,1.55,1.81,3.12-2-3-1-1.5-.51-.75-.44-.79a64,64,0,0,1-3.26-6.46c-.47-1.11-1-2.21-1.39-3.34L399.33,201a32.32,32.32,0,0,1-.92-3.51,36,36,0,0,1-.73-3.55l-.49-3.6-.24-3.63a56.64,56.64,0,0,1,.19-7.25,61.76,61.76,0,0,1,1.11-7.15A43.42,43.42,0,0,1,400.46,165.46Z"
      />
    </g>
    <circle
      cx="449.74"
      cy="178.72"
      r="2.24"
      opacity="0.2"
    />
    <circle
      cx="466.49"
      cy="176.48"
      r="2.24"
      opacity="0.2"
    />
    <circle
      cx="484.06"
      cy="180.96"
      r="2.24"
      opacity="0.2"
    />
    <g opacity="0.2">
      <path
        d="M447.5,174.25a20.11,20.11,0,0,1-1.14-7.82,16.2,16.2,0,0,1,2.26-7.67,15.09,15.09,0,0,1,5.84-5.46,21,21,0,0,1,3.67-1.55c.64-.2,1.28-.33,1.92-.5s1.3-.24,1.95-.34a28.75,28.75,0,0,0-7.15,3.05c-.56.32-1.05.74-1.58,1.09a15.8,15.8,0,0,0-1.43,1.27,13.83,13.83,0,0,0-2.37,3,17.48,17.48,0,0,0-2.35,7.21,22.73,22.73,0,0,0-.1,3.87A24.47,24.47,0,0,0,447.5,174.25Z"
      />
    </g>
    <ellipse
      cx="400.46"
      cy="252.36"
      rx="13.76"
      ry="21.05"
      transform="translate(-70.75 207.54) rotate(-26.78)"
      fill="#f9b499"
    />
    <polygon points="415.25 354.9 415.25 562.28 491.05 603.75 491.05 393.98 415.25 354.9" />
    <polygon
      points="415.25 354.9 415.25 562.28 491.05 603.75 491.05 393.98 415.25 354.9"
      fill="#fff"
      opacity="0.8"
    />
    <path
      d="M366.34,505.35a50.25,50.25,0,0,0,16.31-18.3l12-21.53s1.49-10.94,17.17-11.31c0,0,34.79-12.5,53.17-1.89a3.74,3.74,0,0,1-.57,6.72,24.63,24.63,0,0,1-8.84,1s-25.9-2.92-29.55,4.38c0,0,53.63.73,50.35,6.56,0,0,5.11,9.13-53.21,5.79a1.64,1.64,0,0,0-1.71,1.74h0a1.64,1.64,0,0,0,1.69,1.53c8.81-.33,51.27-1.51,49.95,7,0,0,4.9,5.19-47.92,3.07a.17.17,0,0,0,0,.33l28.62,3.89s10.58-.29,9.85,3.14c0,0,2.19,6-18.24,4.89l-23.8,1.1S410.45,531,365.54,539.31C365.54,539.31,359.88,519.64,366.34,505.35Z"
      fill="#f9b499"
    />
    <path
      d="M465.9,227.51s-.35-.45-4.56.36c0,0-8,22.71,2.28,37.54,0,0,3.68.77,4.3.77Z"
      fill="#2f2e41"
    />
    <path
      d="M498.47,216.85s-25.22-4.53-34.22,10.66c0,0-6.9,7.64-3.52,26.31a27.09,27.09,0,0,0,8.42,15.43c5,4.5,13,8.25,24.54,5.24Z"
    />
    <path
      d="M522.57,246.08c0,13.27-9.53,24-21.29,24a18.74,18.74,0,0,1-2.37-.15c-10.64-1.33-18.9-11.51-18.9-23.87s8.26-22.53,18.9-23.86a17,17,0,0,1,2.37-.15C513,222.07,522.57,232.82,522.57,246.08Z"
      fill="#e6e7e8"
    />
    <path
      d="M498.91,222.35c10.64,1.33,18.9,11.51,18.9,23.86,0,12.1-7.92,22.08-18.22,23.75.56.05,1.12.08,1.69.08,11.76,0,21.29-10.75,21.29-24s-9.53-24-21.29-24a18.74,18.74,0,0,0-2.37.15"
      fill="#2f2e41"
    />
    <path
      d="M500.46,216.85c-14.79,0-26.78,13.09-26.78,29.23s12,29.24,26.78,29.24,26.77-13.09,26.77-29.24S515.25,216.85,500.46,216.85Zm.82,53.25a17.05,17.05,0,0,1-2.37-.16c-10.64-1.32-18.9-11.5-18.9-23.86s8.26-22.54,18.9-23.86a17.05,17.05,0,0,1,2.37-.16c11.76,0,21.29,10.76,21.29,24S513,270.1,501.28,270.1Z"
    />
    <path
      d="M500.46,216.85c-14.79,0-26.78,13.09-26.78,29.23s12,29.24,26.78,29.24,26.77-13.09,26.77-29.24S515.25,216.85,500.46,216.85Zm.82,53.25a17.05,17.05,0,0,1-2.37-.16c-10.64-1.32-18.9-11.5-18.9-23.86s8.26-22.54,18.9-23.86a17.05,17.05,0,0,1,2.37-.16c11.76,0,21.29,10.76,21.29,24S513,270.1,501.28,270.1Z"
      fill="#fff"
      opacity="0.8"
    />
    <path
      d="M433.66,208.61s-15.1,44.76-67.83,48.46c0,0,16.92-3.87,24.45-10.1,1.17-1,2.35-1.9,3.59-2.78,3.72-2.65,13.07-10.6,30.45-32.83,0,0,3.57-12.09,14.28-13.46,0,0,3.3-8,20.32-12.08,0,0,18.76-8.51,32.13,5.56A27.31,27.31,0,0,0,510,201.2s-9.5,22.34-30,9.9a4.84,4.84,0,0,0-4.23-.31c-3.42,1.31-10.79,2.38-20.5-6.18a2,2,0,0,0-2.25-.32c-.62.34-1,1,.13,2.4,0,0,12.11,7.26-4,5.49C449.11,212.18,440,211.91,433.66,208.61Z"
      fill="#2f2e41"
    />
    <path
      d="M448.51,189.47a101.78,101.78,0,0,1,20.71,10.38,44.47,44.47,0,0,1,6.08,4.79c3.34,3.13,10,8.8,15.75,10.21,0,0,8.43,1.54,15.8-8.44,0,0-16.58,1-28.47-7.39a30.59,30.59,0,0,0-4.19-2.42C469,194.09,458.22,189.45,448.51,189.47Z"
      opacity="0.2"
    />
    <path
      d="M584.69,246.08c0,13.27-9.54,24-21.29,24A18.78,18.78,0,0,1,561,270c-10.63-1.33-18.9-11.51-18.9-23.87s8.27-22.53,18.9-23.86a17.09,17.09,0,0,1,2.38-.15C575.15,222.07,584.69,232.82,584.69,246.08Z"
      fill="#e6e7e8"
    />
    <path
      d="M561,222.35c10.64,1.33,18.91,11.51,18.91,23.86,0,12.1-7.92,22.08-18.23,23.75.56.05,1.13.08,1.7.08,11.75,0,21.29-10.75,21.29-24s-9.54-24-21.29-24a18.78,18.78,0,0,0-2.38.15"
      fill="#2f2e41"
    />
    <path
      d="M562.57,216.85c-14.78,0-26.77,13.09-26.77,29.23s12,29.24,26.77,29.24,26.78-13.09,26.78-29.24S577.36,216.85,562.57,216.85Zm.83,53.25a17.09,17.09,0,0,1-2.38-.16c-10.63-1.32-18.9-11.5-18.9-23.86s8.27-22.54,18.9-23.86a17.09,17.09,0,0,1,2.38-.16c11.75,0,21.29,10.76,21.29,24S575.15,270.1,563.4,270.1Z"
    />
    <path
      d="M562.57,216.85c-14.78,0-26.77,13.09-26.77,29.23s12,29.24,26.77,29.24,26.78-13.09,26.78-29.24S577.36,216.85,562.57,216.85Zm.83,53.25a17.09,17.09,0,0,1-2.38-.16c-10.63-1.32-18.9-11.5-18.9-23.86s8.27-22.54,18.9-23.86a17.09,17.09,0,0,1,2.38-.16c11.75,0,21.29,10.76,21.29,24S575.15,270.1,563.4,270.1Z"
      fill="#fff"
      opacity="0.8"
    />
    <path
      d="M140.94,543.05c-22.8,0-39.39-4.47-51-9.73-25-11.3-33.1-28.61-33.18-28.78l.88-.4c.08.17,8.1,17.2,32.77,28.34,22.81,10.29,64.89,17.51,135.89-6.54a354.59,354.59,0,0,0,34.5-13.83c20.88-9.64,76.46-33.85,141.56-50.52,81.16-20.79,148.38-23,199.78-6.49l-.3.91C482.9,417.88,311,490,261.2,513a357.66,357.66,0,0,1-34.6,13.87C191.36,538.79,163.19,543.05,140.94,543.05Z"
    />
    <g opacity="0.25">
      <path
        d="M140.94,543.05c-22.8,0-39.39-4.47-51-9.73-25-11.3-33.1-28.61-33.18-28.78l.88-.4c.08.17,8.1,17.2,32.77,28.34,22.81,10.29,64.89,17.51,135.89-6.54a354.59,354.59,0,0,0,34.5-13.83c20.88-9.64,76.46-33.85,141.56-50.52,81.16-20.79,148.38-23,199.78-6.49l-.3.91C482.9,417.88,311,490,261.2,513a357.66,357.66,0,0,1-34.6,13.87C191.36,538.79,163.19,543.05,140.94,543.05Z"
        fill="#fff"
      />
    </g>
    <path
      d="M295.52,341.22l24.77,27.55s-19.43,32.78-8.57,80.67c0,0,4.26,23.31,30.87,48.89l-43.18,3.6s-24.22-15.37-27.71-81.09C271.7,420.84,266.94,383.23,295.52,341.22Z"
    />
    <path
      d="M295.52,341.22l24.77,27.55s-19.43,32.78-8.57,80.67c0,0,4.26,23.31,30.87,48.89l-43.18,3.6s-24.22-15.37-27.71-81.09C271.7,420.84,266.94,383.23,295.52,341.22Z"
      fill="#fff"
      opacity="0.36"
    />
    <path
      d="M223.43,496.05a53.47,53.47,0,0,1-23.21-5A30,30,0,0,1,183,466.7c-.79-9.6,3.18-22.39,26.54-28.86,1.55-.83,23.59-11.31,97,18.62a260.67,260.67,0,0,0,21.08-19.19c65.94-56.66,108.91-144.16,116.85-161.12,1.29-2.76,2.68-5.43,3.87-7.67,20-37.8,3.46-57.82,3.29-58l.76-.66c.18.21,17.16,20.7-3.17,59.14-1.18,2.23-2.56,4.88-3.84,7.62-8,17-51,104.66-117.07,161.43a262.77,262.77,0,0,1-20.72,18.89q8.31,3.41,17.5,7.53c1.62.73,3.22,1.39,4.75,2,10.17,3.87,44.54,16.62,59.57,18.37l-.12,1c-15.15-1.77-49.6-14.55-59.8-18.43-1.56-.59-3.17-1.26-4.81-2q-9.47-4.24-18-7.72A254.76,254.76,0,0,1,284.38,474C267.46,485,245.05,496,223.43,496.05Zm1.4-59.52c-10.63,0-14.76,2.15-14.85,2.21l-.12.05c-18,5-27,14.59-25.87,27.83a29,29,0,0,0,16.66,23.5,52.51,52.51,0,0,0,22.78,4.93c21.39,0,43.6-11,60.39-21.89a252.56,252.56,0,0,0,21.79-16C263.83,440.23,238.76,436.53,224.83,436.53Z"
      fill="#2f2e41"
    />
    <path
      d="M400.17,233.57s2.64-12.62,40.68-46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      opacity="0.2"
    />
    <path
      d="M309.53,241.63a106.19,106.19,0,0,1-22.88-2.26c-17.58-3.88-23.91-11.23-24.17-11.54l.77-.64c.06.07,6.44,7.44,23.74,11.23,16,3.51,44.85,4.58,91.46-10.24,2.92-.89,71.52-21.41,81.81,6l-.93.35c-10-26.51-79.88-5.59-80.58-5.38C348.85,238.64,326.2,241.63,309.53,241.63Z"
      fill="#2f2e41"
    />
    <path
      d="M458.9,236.36c-7.17-.31-20.58-4.84-21.15-5-14.32-5.64-24.08-13-29-21.73-4.72-8.36-2.13-19,5.79-23.66,13.12-7.76,28.95,6.43,29.11,6.58l-.67.74c-.16-.14-15.45-13.84-27.93-6.45-7.45,4.4-9.89,14.41-5.43,22.29,4.84,8.58,14.44,15.74,28.52,21.29.11,0,13.82,4.66,20.84,5Z"
      fill="#2f2e41"
    />
  </svg>
  <h1 class="text-xl font-bold">Opa! Não conseguimos localizar essa página.</h1>
</div>
