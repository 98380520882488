import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { ImaginePortalModule } from "src/@imagine/i-portal/i-portal.module";
import { I18nInterceptor } from "../core/i18n/i18n.interceptor";
import { RedirectDirective } from "./services/redirect/redirect.directive";
import { RedirectService } from "./services/redirect/redirect.service";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    ImaginePortalModule,
    HttpClientModule,
    TranslocoModule,
  ],
  exports: [CommonModule, RouterModule, ImaginePortalModule, TranslocoModule],
  providers: [
    RedirectService,
    RedirectDirective,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I18nInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
