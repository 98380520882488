import { NgModule } from "@angular/core";
import { RedirectDirective, RedirectWithHrefDirective } from "./redirect.directive";
import { RedirectService } from "./redirect.service";

@NgModule({
  declarations: [RedirectDirective, RedirectWithHrefDirective],
  imports: [],
  exports: [RedirectDirective, RedirectWithHrefDirective],
  providers: [RedirectService]
})
export class RedirectModule {}
