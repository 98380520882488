import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState } from 'src/app/store/central';
import { Language } from 'src/entities/languages.entity';

@Component({
  selector: 'app-language-switcher',
  templateUrl: 'language-switcher.component.html'
})
export class LanguageSwitcherComponent implements OnInit {
  @ViewSelectSnapshot(CentralState.getLanguages)
  public languages!: Language[];

  constructor(private readonly translocoService: TranslocoService) { }

  ngOnInit() { }

  handleLanguageChange(language: Language) {
    if (this.activeLang === language.initials) return;

    this.translocoService.setActiveLang(language.initials === 'br' ? 'pt' : language.initials); // TODO: remover gambiarra @iJhefe

    window.location.reload();
  }


  get activeLang(): string {
    return this.translocoService.getActiveLang() === 'pt' ? 'br' : this.translocoService.getActiveLang()
  }
}
