import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoModule } from '@ngneat/transloco';
import { NgOptimizedImage } from '@angular/common';
import { ImagineOverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [HeaderComponent, NavigationComponent, LanguageSwitcherComponent],
  imports: [SharedModule, HttpClientModule, TranslocoModule, NgOptimizedImage, OverlayModule],
  exports: [HeaderComponent],
  providers: [ImagineOverlayService]
})
export class HeaderModule {}
