import { NgModule, isDevMode } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CentralState } from './central';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ExportState } from './export.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([CentralState, ExportState], {
      developmentMode: isDevMode(),
    }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [],
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
  ],
})
export class StoreModule {}
