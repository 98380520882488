import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { AppService } from '../app.service';
import { RedirectService } from '../shared/services/redirect/redirect.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { CentralHomeComponent } from './central/pages/home/central.component';
import { indicatorsResolve } from './central/pages/search/search.service';

export type RouteData<T> = {
  breadcrumb?: string | ((data: RouteData<T>) => string | null) | null;
  data?: T;
};

export type AppRoute = Route & {
  data?: RouteData<any>;
};

export const pagesRoutes: AppRoute[] = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  //   data: {
  //     breadcrumb: 'Início',
  //   },
  // },
  {
    path: '',
    data: {
      breadcrumb: 'i18n|common.home',
    },
    resolve: {
      state: () => inject(AppService).resolve(),
    },
    children: [
      {
        path: '',
        component: CentralHomeComponent,
      },
      {
        path: 'category',
        loadChildren: () =>
          import('./central/pages/category/category.module').then((m) => m.CategoryModule),
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'search',
        loadComponent: () => import('./central/pages/search/search.component').then((m) => m.CentralSearchComponent),
        data: {
          breadcrumb: 'i18n|common.search',
        },
        resolve: {
          indicators: indicatorsResolve,
        }
      },
    ],
  },
    // Not Found page with external link redirection
  { path: 'not-found', component: NotFoundComponent, canActivate: [ RedirectService ] },
  { path: '**', redirectTo: 'not-found' }
];
