<div class="fixed inset-0 sm:top-auto sm:relative z-10 sm:mt-6 tracking-wider top-[5rem] w-full lg:w-[44rem]">
  <div class="relative p-2 overflow-hidden bg-white border rounded-lg shadow-lg text-primary-600 sm:p-4 border-zinc-300">
    <!-- <div>
      <p class="text-xl font-bold text-primary-600">{{ 'navigation.title' | transloco }}</p>
    </div> -->
    <div class="relative grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-2">
      <a
        [routerLink]="['/']"
        class="relative flex items-center px-4 py-2 space-x-4 transition duration-300 rounded-md hover:bg-zinc-100 group before:absolute before:top-0 before:-right-2 before:h-screen before:w-px before:bg-zinc-100"
      >
        <span class="w-1 h-1 rounded bg-primary-600 shrink-0"></span>
        <span class="text-sm font-semibold text-black">
          {{ 'common.home' | transloco}}
        </span>
      </a>
      <a
        *ngFor="let item of menuItems"
        [routerLink]="['category', item.slug]"
        class="relative flex items-baseline px-4 py-2 space-x-4 transition duration-300 rounded-md hover:bg-zinc-100 group before:absolute before:top-0 before:-right-2 before:h-screen before:w-px before:bg-zinc-100"
        [ngClass]="[item.subTitle ? 'items-baseline' : 'items-center']"
      >
        <span class="w-1 h-1 rounded bg-primary-600 shrink-0"></span>
        <div class="flex flex-col space-y-1">
          <span class="text-sm font-semibold text-black break-words">
            {{ item.title }}
          </span>
          <small *ngIf="item.subTitle" class="text-gray-600 break-words">{{item.subTitle }}</small>
        </div>
      </a>
    </div>
  </div>
</div>
