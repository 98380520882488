import { Component, OnInit } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState } from 'src/app/store/central';
import { CentralMenu } from 'src/entities/central-menu.entity';

@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @ViewSelectSnapshot(CentralState.getMenus)
  public menuItems!: CentralMenu[];

  constructor() { }

  ngOnInit() { }
}
