/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { RedirectService } from './redirect.service';

@Directive({
  selector: ':not(a):not(area)[redirect]',
})
export class RedirectDirective {
  constructor(readonly redirectService: RedirectService) {}

  @Input() redirect!: string;

  @HostListener('click') onClick() {
    // Fallbakcs to default if no url is specified
    if (!this.redirect) {
      return true;
    }
    // Navigates on the requested link redirecting when necessary
    this.redirectService.navigate(this.redirect);
    // Prevents default
    return false;
  }
}

@Directive({
  selector: 'a[redirect],area[redirect]',
})
export class RedirectWithHrefDirective extends RedirectDirective {
  constructor(redirect: RedirectService) {
    super(redirect);
  }

  // Binds the requested url to the href property
  @HostBinding('href') get href() {
    return this.redirect;
  }
}
