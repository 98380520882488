import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MenuCardComponent } from './menu-card.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MenuCardComponent],
  imports: [CommonModule, RouterModule, NgOptimizedImage],
  exports: [MenuCardComponent],
})
export class MenuCardModule {}
