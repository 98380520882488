import { Injectable } from "@angular/core";
import { NavigationEnd, ActivatedRouteSnapshot, Data, Router } from "@angular/router";
import debug from "debug";
import { BehaviorSubject, filter } from "rxjs";
import { Breadcrumb } from "./breadcrumb.interface";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly log = debug(BreadcrumbService.name)

  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    let breadcrumbs: Breadcrumb[] = [];
    const root = this.router.routerState.snapshot.root;
    this.log('Inicializando breadcrumb service', {
      root,
      breadcrumbs
    })

    this.defineBreadcrumb(breadcrumbs);

    this.router.events.pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd)
      ).subscribe(() => {
        breadcrumbs = [];

        this.defineBreadcrumb(breadcrumbs);
      });
    }

  private defineBreadcrumb(breadcrumbs: Breadcrumb[]) {
    this.addBreadcrumb(this.router.routerState.snapshot.root, [], breadcrumbs);

    this._breadcrumbs$.next(breadcrumbs);
  }

    private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
      if (route) {
        // Construct the route URL
        const routeUrl = parentUrl.concat(route.url.map(url => url.path));

        // Add an element for the current route part
        if (route.data["breadcrumb"] && !breadcrumbs.find(b => b.url === '/' + routeUrl.join('/'))) {
          const breadcrumb = {
            label: this.getLabel(route.data),
            url: '/' + routeUrl.join('/')
          };
          breadcrumbs.push(breadcrumb);

          this.log('Criado nova breadcrumb', breadcrumb, route.data)
        } else {
          this.log('Rota não possui breadcrumb')
        }

        this.log('Breadcrumbs:', breadcrumbs)

        // Add another element for the next route part
        this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
      } else {
        this.log('Rota não encontrada')
      }
    }

    private getLabel(data: Data) {

      // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
      return typeof data["breadcrumb"] === 'function' ? data["breadcrumb"](data) : data["breadcrumb"];
    }

  }
