<div class="relative w-full h-56 p-8 overflow-hidden rounded-md shadow-md bg-primary-500 group">
  <div
    class="absolute inset-0 after:absolute after:bg-black after:bg-opacity-0 after:w-full after:h-full after:inset-0 after:transition after:duration-300 group-hover:after:bg-opacity-20"
  >
    <img
      *ngIf="category.image"
      [ngSrc]="category.image"
      [alt]="category.title"
      [fill]="true"
      class="w-full h-full transition duration-300 rounded-md"
    />
  </div>
  <img
    *ngIf="category.icon"
    [ngSrc]="category.icon"
    alt=""
    [width]="48"
    [height]="48"
    class="absolute hidden object-contain top-5 right-5 lg:block"
  />
  <div
    class="absolute inset-0 flex flex-col justify-between h-full p-4 text-left text-white rounded-b-md"
  >
    <span
      *ngIf="!category.hideTitle"
      class="text-xl font-bold break-words"
      [ngClass]="{'lg:max-w-xs 2xl:max-w-sm': category.icon}"
    >
      {{ category.title }}
    </span>
    <span *ngIf="category.subTitle" class="text-xl font-light break-words">
      {{ category.subTitle }}
    </span>
  </div>
</div>
