import { Endpoint } from 'src/app/api/api.decorator';
import { Category, CentralEntityMetadata, CentralIndicator, CentralItem } from '@imagineapps-team/report-interfaces';

@Endpoint('indicator')
export class Indicator implements CentralIndicator {
  refer!: string;
  fRef!: number;
  items!: CentralItem[];
  meta!: CentralEntityMetadata;
  position!: number;
  id!: number;
  code!: string | null;
  title!: string;
  context!: string | null;
  moreInfo!: string | null;
  framework!: Category | null;
  categories!: Category[];
}

@Endpoint('indicators-map')
export class IndicatorMap {}

export class IndicatorBasic {
  id!: number;
  title!: string;
  code!: string
  refer!: string
  position!: number
}
