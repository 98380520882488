import { Injectable } from "@angular/core";
import { createStore, select, withProps } from "@ngneat/elf";
import { addEntities, getActiveEntity, getAllEntities, getEntity, resetActiveId, selectActiveEntity, setActiveId, setEntities, updateEntities, withActiveId, withEntities } from "@ngneat/elf-entities";
import { Indicator, IndicatorBasic } from "src/entities/indicator.entity";

const indicatorStore = createStore(
  { name: 'indicator' },
  withEntities<Indicator>(),
  withActiveId()
)

const stubIndicatorStore = createStore(
  { name: 'stub_indicator'},
  withProps<IndicatorBasic[]>([]),
)

@Injectable({ providedIn: 'root' })
export class IndicatorRepository {
  indicators$ = indicatorStore.pipe(select((state) => state.entities));
  indicators = indicatorStore.query(getAllEntities())
  activeIndicator = indicatorStore.query(getActiveEntity())
  activeIndicator$ = indicatorStore.pipe(selectActiveEntity())

  stubIndicators$ = stubIndicatorStore.pipe(select((state) => state));
  stubIndicators = stubIndicatorStore.query((state) => state)

  setStubIndicators(indicators: IndicatorBasic[]) {
    stubIndicatorStore.update(() => indicators)
  }

  setIndicators(indicators: Indicator[]) {
    indicatorStore.update(setEntities(indicators.map((indicator) => indicator)))
  }

  addIndicator(indicator: Indicator) {
    indicatorStore.update(addEntities(indicator))
  }

  updateIndicator(indicatorId: Indicator['id'], body: Partial<Indicator>){
    indicatorStore.update(updateEntities(indicatorId, (entity) => ({ ...entity, body })))
  }

  getIndicator(indicatorId: Indicator['id']) {
    return indicatorStore.query(getEntity(indicatorId))
  }

  setActiveIndicator(indicatorId: Indicator['id']) {
    indicatorStore.update(setActiveId(indicatorId))
  }

  resetActiveIndicator() {
    indicatorStore.update(resetActiveId())
  }
}
