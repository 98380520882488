import { Type } from "@angular/core"
import { APIResolver } from "./api.resolver"

export interface EndpointMetadata {
  url: string,
  hasI18n: boolean
}

export const ENDPOINT_METADATA = Symbol('endpoint')

export const Api = (entity: Type<any>): PropertyDecorator => (target, propertyKey) => {
  Reflect.defineProperty(target, propertyKey, {
    get: () => APIResolver.get(entity),
    enumerable: true
  })
}

export const Endpoint = (url: string, hasI18n = true): ClassDecorator => (target) => {
  Reflect.defineMetadata(ENDPOINT_METADATA, {
    url,
    hasI18n
  }, target)
}
