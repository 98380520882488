import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, isDevMode } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import Hotjar from '@hotjar/browser';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CentralState } from './store/central';
import { CentralCustomization } from 'src/entities/central-customization.entity';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @SelectSnapshot(CentralState.getCustomization)
  customization!: CentralCustomization | undefined;

  constructor(@Inject(DOCUMENT) private document: Document, private readonly translocoService: TranslocoService, private readonly router: Router){
    if(!isDevMode() && this.customization?.integrations?.hotjarId) {
      Hotjar.init(+this.customization.integrations.hotjarId, 6);

      this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          Hotjar.stateChange(event.urlAfterRedirects);
        }
      });
    }
  }

  ngOnInit(): void {
    const currentLang = this.translocoService.getActiveLang()

    this.document.documentElement.lang = currentLang;
  }

}
