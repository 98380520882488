import { Endpoint } from "src/app/api/api.decorator";

@Endpoint('customization')
export class CentralCustomization {
  title!: string;
  description!: string
  logo!: string
  favicon!: string
  button!: {
    link: string,
    title: string,
    visible: boolean
  }
  integrations!: CentralCustomizationIntegrations | null
}

export interface CentralCustomizationIntegrations {
  gaTrackingId: string;
  hotjarId: string
}
