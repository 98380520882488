import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ImaginePortalOutputComponent } from './i-portal-output.component';
import { ImaginePortalComponent } from './i-portal.component';
import { ImaginePortalService } from './i-portal.service';
/**
 * Componente para criar portais de conteúdo.
 *
 * @author https://github.com/haydenbr/ngx-portal/
 */
@NgModule({
  declarations: [ImaginePortalComponent, ImaginePortalOutputComponent],
  imports: [CommonModule],
  exports: [ImaginePortalComponent, ImaginePortalOutputComponent],
})
export class ImaginePortalModule {
  static forRoot(): ModuleWithProviders<ImaginePortalModule> {
    return {
      ngModule: ImaginePortalModule,
      providers: [ImaginePortalService],
    };
  }
}
