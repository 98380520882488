import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider';
import { Carousel } from 'src/entities/central.entity';

export type DeepPartial<T> = T extends Promise<infer Y>
  ? Promise<DeepPartial<Y>>
  : T extends (...args: any[]) => any
  ? T
  : T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

const MutationPlugin: KeenSliderPlugin = (slider) => {
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      slider.update();
    });
  });
  const config = { childList: true };

  let timeout: number | undefined;
  let mouseOver = false;

  function clearNextTimeout() {
    window.clearTimeout(timeout);
  }
  function nextTimeout() {
    window.clearTimeout(timeout);
    if (mouseOver) return;
    timeout = window.setTimeout(() => {
      slider.next();
    }, 5000);
  }

  slider.on('created', () => {
    observer.observe(slider.container, config);
    slider.container.addEventListener('mouseover', () => {
      mouseOver = true;
      clearNextTimeout();
    });
    slider.container.addEventListener('mouseout', () => {
      mouseOver = false;
      nextTimeout();
    });
    nextTimeout();
  });

  slider.on('destroyed', () => {
    observer.disconnect();
  });
  slider.on('dragStarted', clearNextTimeout);
  slider.on('animationEnded', nextTimeout);
};

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['../../../../node_modules/keen-slider/keen-slider.min.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @Input()
  useBreadcrumb = true;

  @Input()
  carouselLoop = true;

  @Input()
  carouselData: Carousel[] = [];

  @Input()
  height = 'h-[93vh]';

  @Input()
  showScrolldownIcon = true;

  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  interval: any = 0;
  pause = false;
  slider!: KeenSliderInstance;
  currentSlide = 1;
  dotHelper: Array<number> = [];

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.slider = new KeenSlider(
        this.sliderRef.nativeElement,
        {
          loop: this.carouselLoop && this.length > 1,
          defaultAnimation: {
            duration: 2000,
          },
        },
        this.length > 1 ? [MutationPlugin] : []
      );
    }
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  resetInterval() {
    clearInterval(this.interval);
  }

  setInterval() {
    this.resetInterval();
    this.interval = setInterval(() => {
      if (!this.pause) {
        this.slider.next();
      }
    }, 5500);
  }

  setPause(active: boolean) {
    this.pause = active;
    this.setInterval();
  }

  get length(): number {
    return this.carouselData.length;
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
