import { Component, Input } from '@angular/core';
import { CentralMenu } from 'src/entities/central-menu.entity';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
})
export class MenuCardComponent {
  @Input()
  category!: CentralMenu;

  constructor() {}
}
