import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImaginePortalService {
  private portals: {
    [portalKey: string]: BehaviorSubject<TemplateRef<any> | undefined>;
  } = {};

  publishToPortal(portalKey: string, template: TemplateRef<any>): void {
    this._getPortal(portalKey).next(template);
  }

  getPortal(portalKey: string): Observable<TemplateRef<any> | undefined> {
    return this._getPortal(portalKey).asObservable();
  }

  clearPortal(portalKey: string): void {
    this._getPortal(portalKey).next(undefined);
  }

  private _getPortal(portalKey: string): BehaviorSubject<TemplateRef<any> | undefined> {
    let portal$ = this.portals[portalKey];

    if (!portal$) {
      portal$ = new BehaviorSubject<TemplateRef<any> | undefined>(undefined);
      this.portals[portalKey] = portal$;
    }

    return portal$;
  }
}
