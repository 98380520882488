import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";

@Injectable()
export class I18nInterceptor implements HttpInterceptor {
  constructor(private translocoService: TranslocoService){}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const activeLang = this.translocoService.getActiveLang()

    const request = req.clone({
      headers: req.headers.set(
        'Accept-Language',
        activeLang === 'pt' ? 'br' : activeLang
      ),
    });

    return next.handle(request);
  }
}
