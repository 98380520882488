import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { delay, map, Observable, pipe } from 'rxjs';

import { ImaginePortalService } from './i-portal.service';

@Component({
  selector: 'i-portal-output, app-portal-output',
  template: `
    <ng-container *ngIf="portalContents$">
      <ng-container *ngTemplateOutlet="portalContents$ | async"></ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImaginePortalOutputComponent implements OnInit {
  @Input() target: string = 'default-portal-target';
  portalContents$!: Observable<TemplateRef<any>>;

  constructor(private portals: ImaginePortalService) {}

  ngOnInit(): void {
    if (!this.target) {
      throw new Error('i-portal-output must have a target');
    }

    this.portalContents$ = this.portals.getPortal(this.target).pipe(
      pipe(delay(0)), // prevents change after checked error
      map((x) => x as TemplateRef<any>)
    );
  }
}
