import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import debug from 'debug';
import { Subject, take, takeUntil } from 'rxjs';

@Injectable()
export class ImagineOverlayService implements OnDestroy {
  private overlayReference!: OverlayRef;

  private log = debug(ImagineOverlayService.name);
  private destroy$ = new Subject();

  constructor(private readonly overlay: Overlay) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  createConnectedOverlay(toElement: HTMLElement): OverlayRef {
    const element = new ElementRef(toElement);

    this.overlayReference = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'bg-black/20',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPush(false)
        .withPositions([
          new ConnectionPositionPair(
            { originX: 'start', originY: 'bottom' },
            { overlayX: 'start', overlayY: 'top' }
          ),
          new ConnectionPositionPair(
            { originX: 'end', originY: 'bottom' },
            { overlayX: 'end', overlayY: 'top' }
          ),
          new ConnectionPositionPair(
            { originX: 'start', originY: 'top' },
            { overlayX: 'start', overlayY: 'bottom' }
          ),
          new ConnectionPositionPair(
            { originX: 'end', originY: 'top' },
            { overlayX: 'end', overlayY: 'bottom' }
          ),
        ]),
    });

    this.overlayReference
      .attachments()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.log('Overlay anexado');
      });

    this.overlayReference
      .detachments()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.log('Overlay desanexado');
      });

    this.overlayReference
      .backdropClick()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.detachOverlay();
      })

    return this.overlayReference;
  }

  detachOverlay(): void {
    if (!this.overlayReference)
      return this.log('OverlayReference não encontrado');

    if (this.overlayReference.hasAttached()) {
      this.log('Desanexando overlay...');
      this.overlayReference.detach();
    } else {
      this.log('Nenhuma overlay encontrada para desanexar');
    }
  }

  get overlayRef(): OverlayRef {
    return this.overlayReference;
  }
}
