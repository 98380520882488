<div class="relative navigation-wrapper">
  <div
    class="relative w-full h-full text-white keen-slider min-h-[37.5rem]"
    #sliderRef
  >
    <ng-container *ngFor="let carousel of carouselData" [ngTemplateOutlet]="carouselTemplate" [ngTemplateOutletContext]="{ carousel }"></ng-container>
    <ng-container *ngIf="length > 1">
      <div class="absolute inset-y-0 items-center hidden md:flex left-5">
        <svg
          class="w-5 h-5 cursor-pointer fill-current"
          [ngClass]="{'text-opacity-50': currentSlide === 0}"
          (click)="slider.prev()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          stroke="currentColor"
        >
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </svg>
      </div>
      <div class="absolute inset-y-0 items-center hidden md:flex right-5">
        <svg
          *ngIf="slider"
          class="w-5 h-5 cursor-pointer fill-current"
          [ngClass]="{'text-opacity-50': slider.size - 1 === currentSlide}"
          (click)="slider.next()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          stroke="currentColor"
        >
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </div>
      <!-- <div class="absolute inset-x-0 bottom-0 mb-2">
        <div class="flex justify-center space-x-2">
          <button
            (click)="slider.moveToSlideRelative(i)"
            *ngFor="let slide of dotHelper; let i = index"
            class="w-3 h-3 p-0.5 cursor-pointer duration-300 border-2 border-white rounded-full transition-colors"
            [ngClass]="{'bg-transparent': i !== currentSlide, 'bg-white': i === currentSlide}"
          >
          </button>
        </div>
      </div> -->
    </ng-container>
  </div>
  <div *ngIf="showScrolldownIcon" class="absolute flex justify-center w-full bottom-10">
    <div class="flex items-center justify-center w-10 h-10 p-2 bg-white rounded-full shadow-lg animate-bounce ring-1 ring-primary-900/5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-6 h-6 text-primary-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
      </svg>
    </div>
  </div>
</div>

<ng-template #carouselTemplate let-carousel="carousel">
  <div
    class="relative px-4 py-8 md:py-16 keen-slider__slide bg-primary-800 sm:px-20"
  >
    <img
      *ngIf="carousel.image"
      [ngSrc]="carousel.image"
      priority
      class="object-none object-left lg:object-right"
      [alt]="carousel.title"
      fill
    />
    <div class="container relative flex flex-col items-center h-full mx-auto text-left md:items-start">
      <div
        *ngIf="useBreadcrumb"
        class="px-2 py-1 text-gray-700 bg-white rounded-lg"
      >
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="flex-grow md:flex-grow-0 h-full lg:w-[45%]" [ngClass]="{ 'lg:mt-8': useBreadcrumb }">
        <h1 *ngIf="!carousel.hideTitle" class="text-3xl font-bold tracking-tight lg:text-4xl 2xl:text-7xl sm:leading-none font-title">{{ carousel.title }}</h1>
        <div class="leading-relaxed prose-sm prose xl:prose-base md:prose-img:max-w-sm" [ngClass]="{ 'mt-4 lg:mt-8': !carousel.hideTitle}" [innerHTML]="sanitizeHtml(carousel.description)"></div>
      </div>
      <div
        *ngIf="carousel.actions?.length"
        class="flex flex-col w-full mt-4 space-y-4 md:space-y-0 md:flex-row md:space-x-4 lg:mt-8"
      >
        <app-button
          *ngFor="let action of carousel.actions"
          [label]="action.label"
          [variant]="action.variant"
          [href]="action.url"
          [fullWidth]="true"
        ></app-button>
      </div>
    </div>
  </div>
</ng-template>
