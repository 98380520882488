import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APIService } from './api.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    APIService
  ],
  exports: [HttpClientModule],
})
export class ApiModule {
  constructor(private readonly apiService: APIService){}
}
