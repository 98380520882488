import { Endpoint } from "src/app/api/api.decorator";
import { Category } from "./category.entity";
import { File } from "./file.entity";
import { Indicator, IndicatorBasic } from "./indicator.entity";

@Endpoint('menus')
export class CentralMenu {
  id!: string;

  slug!: string;

  title!: string;

  subTitle!: string | null;

  category!: Category | null;

  children!: CentralMenu[] | null;

  description!: string | null;

  image!: string | null;
  imageSrc!: string | null;

  parent!: CentralMenu | null;

  properties!: CentralMenuProperties | null;

  permalink!: string | null;

  hideTitle!: boolean;

  backdropImage!: string | null;
  backdropImageSrc!: string | null;

  icon!: string | null;
  iconSrc!: string | null;

  get name() {
    return this.title
  }
}

@Endpoint('menu-map')
export class MenuMap {}

export type MenuMapData = Map<number, IndicatorBasic[]>

export interface MenuOrCategory {
  id: string;
  slug: string;
  title?: string;
  name?: string;
  description: string;
  category: Category;
  children: CentralMenu[];
  parent: CentralMenu;
  properties: CentralMenuProperties;
  image: File | null;
}

export interface CentralMenuProperties {
  externalUrl?: string;

  specialAction?: CentralMenuSpecialAction;
}

export type CentralMenuSpecialAction = 'listAllIndicators' | 'listAllODS';
