<app-carousel
  [carouselData]="carousels"
  [useBreadcrumb]="false"
  [showScrolldownIcon]="false"
></app-carousel>
<!-- <section class="-mx-4 sm:-mx-20 before:w-full before:bg-primary-50 before:h-96 before:absolute">
  <div class="container relative py-12 mx-auto h-96">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold text-tertiary-600">Lorem ipsum, ipsum dolor</h2>
      <h3 class="text-lg font-light text-justify text-gray-500">Porem ius aspelitate nus, omnimi, sae nem dolor acil idebis ipsa dolores dolestis as vit et eataturem faceptae natus.
      Rum dicidendaes sit ipsa quidebi tiores estius, tem</h3>
    </div>
  </div>
</section> -->
<section class="px-4 py-4 md:py-0 sm:px-20 md:-mt-6">
  <section class="container relative pb-12 mx-auto">
    <div class="grid w-full grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
      <a *ngFor="let category of centralMenus" [redirect]="getMenuLink(category)">
        <app-menu-card [category]="category"></app-menu-card>
      </a>
    </div>
  </section>
</section>
